import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const ArrowIcon = styled(
    React.forwardRef(({ color = '#7D86A9', ...props }, forwardedRef) => (
        <svg width="13px" height="6px" {...props} version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.503.198A.767.767 0 0 0 11.976 0h-10.5A.768.768 0 0 0 .95.198a.608.608 0 0 0-.223.469c0 .18.074.336.223.468l5.25 4.667A.768.768 0 0 0 6.726 6a.767.767 0 0 0 .527-.198l5.25-4.667a.608.608 0 0 0 .223-.468.609.609 0 0 0-.223-.47z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    )),
    {}
);
