import { makeAutoObservable, reaction } from 'mobx';
import DeadPoll from '@a2d24/deadpoll';

const websocketEndpoint = process.env.REACT_APP_DEADPOLL_WS_URL;
const apiEndpoint = process.env.REACT_APP_DEADPOLL_API_ENDPOINT;

export default class DeadpollStore {
    deadpollClient = null;
    connected = false;
    siteStatus = {};
    practiceStreamingList = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);

        reaction(
            () => this.rootStore.authStore.emrToken,
            (token) => {
                if (token) {
                    this.deadpollClient = new DeadPoll(
                        `${websocketEndpoint}`,
                        `${apiEndpoint}`,
                        token
                    );
                    makeAutoObservable(this.deadpollClient);
                } else {
                    if (this.deadpollClient) {
                        // Make sure all subscriptions cancelled which will close the WS before destroying the client
                        this.deadpollClient.unsubscribeAll();
                        this.deadpollClient = null;
                    }
                }
            }
        );

        reaction(
            () => this.deadpollClient?.connected,
            (connected) => {
                this.connected = !!connected;
            }
        );
    }
}
