import React from 'react';
import { HoverStyles } from './ButtonHover';
import { styled } from '@a2d24-ui/theme';

const containedButton = {
    border: 'none',
    color: '$primaryContrastText',
    boxShadow:
        '0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
    transition: 'box-shadow 0.2s',
};

const defaultButton = {
    border: 'none',
    color: '$primary',
    backgroundColor: 'transparent',
};

const outlinedButton = {
    border: `1px solid $primary`,
    color: '$primary',
    backgroundColor: 'transparent',
};

const iconButton = {
    backgroundColor: 'transparent',
    borderRadius: '999999px',
    border: 'none',
    color: '$primary',
    fontSize: '16px',
    minWidth: '32px',
    minHeight: '32px',
};

export const Button = styled('button', {
    position: 'relative',
    display: 'inline-block',
    boxSizing: 'border-box',
    borderRadius: '20px',
    padding: '0 16px',
    minWidth: '64px',
    height: '36px',
    verticalAlign: 'middle',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    // textTransform: "uppercase",
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '36px',
    overflow: 'hidden',
    outline: 'none',
    cursor: 'pointer',
    userSelect: 'none',
    ...HoverStyles,
    variants: {
        variant: {
            contained: containedButton,
            outlined: outlinedButton,
            default: defaultButton,
            icon: iconButton,
            text: {
                ...outlinedButton,
                border: 'none',
                backgroundColor: 'transparent',
                fontSize: '$6',
                fontWeight: 'normal',
            },
        },
        color: {
            primary: { backgroundColor: '$primary' },
            secondary: { backgroundColor: '$secondary' },
            transparent: { backgroundColor: 'transparent' },
        },
    },
    defaultVariants: {
        variant: 'contained',
        color: 'primary',
    },
    compoundVariants: [
        {
            variant: 'outlined',
            color: 'secondary',
            css: {
                border: `1px solid $secondary`,
                color: '$secondary',
                backgroundColor: 'transparent',
            },
        },
        {
            variant: 'outlined',
            color: 'primary',
            css: {
                border: `1px solid $primary`,
                color: '$primary',
                backgroundColor: 'transparent',
            },
        },
        {
            variant: 'icon',
            color: 'primary',
            css: {
                backgroundColor: 'transparent',
            },
        },
        {
            variant: 'text',
            color: 'primary',
            css: {
                backgroundColor: 'transparent',
            },
        },
        {
            variant: 'text',
            color: 'secondary',
            css: {
                backgroundColor: 'transparent',
                color: '$secondary',
            },
        },
    ],
});
