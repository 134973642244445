/*global globalThis*/

import React from 'react';

import { reaction } from 'mobx';

import AuthStore from './auth.store';
import UIStore from './ui.store';
import DeadpollStore from './deadpoll.store';
import UserProfileStore from './userprofile.store';

import MfaStore from '@a2d24/auth0-mfa';

const AUTH_DOMAIN = 'auth.netcaretech.co.za';
const AUTH_CLIENT_ID = 'I0sHqWpkZqsfGZR6D4WgQTZHrkdqfuJB';
const AUTH_AUDIENCE = 'https://services.netcare.co.za';
const AUTH_MFA_AUDIENCE = 'https://netcareplus.eu.auth0.com/mfa/';
const AUTH_LOCALSTORAGE_CACHE_KEY = 'Emr-AuthProviderToken';
const AUTH_MFA_DOMAIN = process.env.REACT_APP_AUTH_MFA_DOMAIN;
const AUTH_MFA_DOMAIN_PROTOCOL = process.env.REACT_APP_AUTH_MFA_DOMAIN_PROTOCOL || 'https';

class RootStore {
    constructor() {
        this.mfaStore = new MfaStore(
            AUTH_DOMAIN,
            AUTH_CLIENT_ID,
            AUTH_AUDIENCE,
            AUTH_MFA_AUDIENCE,
            AUTH_LOCALSTORAGE_CACHE_KEY,
            {
                mfa_domain: AUTH_MFA_DOMAIN,
                mfa_domain_protocol: AUTH_MFA_DOMAIN_PROTOCOL,
            }
        );
        this.authStore = new AuthStore(this);
        this.uiStore = new UIStore(this);
        this.deadpollStore = new DeadpollStore(this);
        this.userProfileStore = new UserProfileStore(this);

        // Whenever the mfaStore toggles its authenticated state, update authStore
        reaction(
            () => this.mfaStore.authenticated,
            (authenticated) => {
                this.authStore.setAuthenticated(authenticated);
            },
            {
                fireImmediately: true, // For initial run during construction
            }
        );

        // Whenever the authStore changes its initialUserProfile, update userProfileStore
        reaction(
            () => this.authStore.initialUserProfile,
            (initialUserProfile) => {
                this.userProfileStore.initializeProfile(initialUserProfile);
            },
            {
                fireImmediately: true, // For initial run during construction
            }
        );
    }
}

const rootStore = new RootStore();

const StoresContext = React.createContext(rootStore);
export const useStores = () => React.useContext(StoresContext);

export default rootStore;

globalThis.rootStore = rootStore;
