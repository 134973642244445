import { createStitches } from '@stitches/react';

const emergencyDivHeight = '56px';
const headerHeight = '70px';
const sidebarOpenWidth = '224px';
const sidebarClosedWidth = '70px';

export const { theme, styled, createTheme, css, keyframes } = createStitches({
    theme: {
        colors: {
            hiContrast: '#000',
            primary: 'hsl(191,41%,48%)', //#499bad
            primary2: 'hsl(191,41%,65%)',
            primary3: 'hsl(191,41%,80%)',
            primary4: 'hsl(191,41%,95%)',
            primary5: 'hsl(191,50%,26%)',
            primary6: 'hsl(192,38%,90%)', //#DBEBEF
            primary7: 'hsl(192,38%,95%)',
            primary8: 'hsl(192,38%,98%)',
            primaryBlack: 'hsl(191,41%,12%)',
            secondary: 'hsl(40,75%,58%)',
            secondary2: 'hsl(40,75%,68%)',
            secondary3: 'hsl(40,75%,78%)',
            secondary4: 'hsl(40,75%,88%)',
            success: '#72c04f',
            warning: 'hsl(0,64%,62%)', // #dc6262
            warning1: 'hsl(0,46%,80%)', // #dc6262
            primaryText: '#4c536b',
            primaryTextDark: '#215763',
            primaryText1: 'hsl(224,10%,71%)',
            primaryText2: 'hsl(220,9%,87%)',
            primaryText3: 'hsl(228,20%,81%)',
            secondaryTextDark: '#C9911D',
            background: '#f6f6f8',
            lightText: '#7d86a9',
            lightText1: 'hsl(227,5%,61%)',
            lightText2: 'hsl(231,20%,87%)',
            overlay: 'rgba(0, 0, 0, 0.56)',
            placeholderText: '$lightText',
            modalBackground: '$primaryText2',
            darkText: '#202020',
            white: '#fff',
            faintBorder: 'hsl(0,0%,94%)',
            primaryContrastText: '$white',
            panel: '$white',
            backgroundCard: '$panel',
            tableHeaderTextColor: '$lightText',
            tableActionsFontColor: '$primary',
            tableIndicatorWaitingColor: '$warning',
            tableIndicatorConsultingColor: '$lightText',
            tableIndicatorConsultedColor: '#56aa31',
            tablePrimaryTextColor: '$darkText',
            tableSecondaryTextColor: '$lightText',
            separatorColor: 'hsl(0,0%,85%)', // #d9d9d9
            cardIconSeparatorColor: '#d8dae5',
            chipText: '$primary5',
            disabled: 'hsl(210,17%,98%)', // #F8F9FA
            chipBackground: '$primary6',
            chipBackgroundGray: '$primaryText2',
            selectHoverColor: '$primary7',
            inputBoxShadow: '#e5e5e5',
            scrollbarThumb: '#BEC2D3',
            scrollbarTrack: 'transparent',
            inputBorder: '$lightText',
            stepperBackgroundInactive: '#e3e8ec',
            morning: '#bedce3',
            afternoon: '#f2e7be',
            evening: '#f2dab4',
            buttonGroupBackground: '#ededf0',
            disabledButton: '#dad8d5',
            verticalSeparatorColor: 'hsl(0,0%,95%)', // #f3f3f3,
            containerBackground: 'hsl(240,18%,96%)', //#F2F2F6

            tableHeaderBackground: 'hsla(228,20%,58%,0.1)',
            tableHeaderText: '$lightText',
        },
        filters: {
            datePickerCalendarIconFilter: 'invert(0)',
        },
        shadows: {
            primary: '$colors$primary',
            lightText: '$colors$lightText',
        },
        space: {
            0: '4px',
            1: '5px',
            2: '10px',
            3: '15px',
            4: '20px',
            5: '25px',
            6: '35px',
            7: '45px',
            8: '65px',
            9: '80px',
            emergencyDivHeight,
            headerHeight,
            sidebarOpenWidth,
            sidebarClosedWidth,
        },
        radii: {
            1: '4px',
            2: '5px',
            3: '6px',
            4: '8px',
            5: '12px',
            6: '17px',
            round: '50%',
            pill: '9999px',
        },
        fontSizes: {
            1: '0.6em',
            2: '0.65em',
            3: '0.75em',
            4: '0.85em',
            5: '0.95em',
            6: '1em',
            7: '1.35em',
            8: '1.75em',
            9: '2.95em',
            10: '2.2em',
            p7: '18px',
        },
        lineHeights: {
            1: '1.2em',
            2: '1.5em',
            3: '1.8em',
            4: '2.2em',
            5: '2.5em',
            6: '2.8em',
            7: '3.2em',
            8: '3.5em',
            9: '4.5em',
            10: '5.5em',
            p7: '18px',
        },
        margin: {
            1: '4px',
            2: '6px',
            3: '8px',
            4: '12px',
        },
        sizes: {
            1: '1.2em',
            2: '1.5em',
            3: '1.8em',
            4: '2.2em',
            5: '2.5em',
            6: '2.8em',
            7: '3.2em',
            8: '3.5em',
            9: '4.5em',
            10: '5.5em',
            p7: '18px',
            inputFieldHeight: '45px',
            checkboxSize: '25px',
            checkboxSizeCompact: '20px',
            emergencyDivHeight,
            headerHeight,
            sidebarOpenWidth,
            sidebarClosedWidth,
        },
    },
    media: {
        bp1: '(min-width: 520px)',
        bp2: '(min-width: 900px)',
        bp3: '(min-width: 1200px)',
        bp4: '(min-width: 1800px)',
        bp5: '(min-width: 1367px)',
        motion: '(prefers-reduced-motion)',
        hover: '(any-hover: hover)',
        dark: '(prefers-color-scheme: dark)',
        light: '(prefers-color-scheme: light)',
    },
    utils: {
        marginX: (value) => ({ marginLeft: value, marginRight: value }),
        marginY: (value) => ({ marginTop: value, marginBottom: value }),
        px: (value) => ({ paddingLeft: value, paddingRight: value }),
        py: (value) => ({ paddingTop: value, paddingBottom: value }),
        square: (value) => ({ width: value, height: value }),
        minSquare: (value) => ({ minWidth: value, minHeight: value }),
    },
});

export const darkTheme = createTheme({
    colors: {
        hiContrast: 'hsl(206,2%,93%)',
        loContrast: 'hsl(206,8%,8%)',

        gray100: 'hsl(206,8%,12%)',
        gray200: 'hsl(206,7%,14%)',
        gray300: 'hsl(206,7%,15%)',
        gray400: 'hsl(206,7%,24%)',
        gray500: 'hsl(206,7%,30%)',
        gray600: 'hsl(206,5%,53%)',
        background: '$gray100',
        backgroundCard: '$gray200',
        primaryText: '$hiContrast',
        darkText: '$gray600',
        tablePrimaryTextColor: '$gray600',
        separatorColor: '$gray400',
    },
    filters: {
        datePickerCalendarIconFilter: 'invert(1)',
    },
    space: {},
    fonts: {},
});

export const Grid = styled('div', { display: 'grid' });
export const Flex = styled('div', { display: 'flex' });
export const ColumnFlex = styled(Flex, { flexDirection: 'column' });
export const Card = styled('div', {
    margin: '20px',
    padding: '20px',
    backgroundColor: '$panel',
    borderRadius: '10px',
    // boxShadow: "rgba(149, 157, 165, 0.2) 0 8px 24px",
    height: 'fit-content',
    width: '100%',

    variants: {
        margin: {
            none: { margin: 0 },
        },
    },
});

export const Box = styled(ColumnFlex, {
    border: '1px solid $separatorColor',
    borderRadius: '$1',
});

const Heading = styled('h1', { margin: '20px' });

const Subtitle = styled('h2', { margin: '20px 20px 20px 0' });

const SectionHeading = styled('h3', { margin: '10px 10px 10px 0' });

const SectionText = styled('p', { margin: '10px 10px 10px 0', fontSize: '1.17em' }); // Text size to match h3

const SubsectionHeading = styled('h5', { margin: '10px 10px 10px 0' });

const Primary = styled('p', { fontSize: '$5', color: '$primaryText' });

const Link = styled('a', { color: 'var(--colors-primary)', textDecoration: 'none' });

const SummarySectionHeading = styled('p', {
    fontSize: '$4',
    color: '$lightText',
    textTransform: 'uppercase',
    margin: '10px 0',
});

export const Text = {
    Heading,
    Subtitle,
    SectionHeading,
    SectionText,
    Link,
    Primary,
    SummarySectionHeading,
    SubsectionHeading,
};
