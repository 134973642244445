import React, { useState } from 'react';
import { styled } from '@a2d24-ui/theme';
import useEngineListener from '@a2d24/react-engine-dom/hooks/useEngineListener';
import { getEngine } from '@a2d24/react-engine';
import UserProfileMenu from './UserProfileMenu';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@a2d24/emr-state/stores';

const engine = getEngine();

const Header = ({ open, setOpen }) => {
    const { authStore } = useStores();
    const [headerComponent, setHeaderComponent] = useState(null);
    const navigate = useNavigate();

    const logout = () => authStore.logout();

    useEngineListener('registerHeaderComponent', (payload) => {
        setHeaderComponent(payload);
    });

    return (
        <Container>
            {/* <Burger
                open={open}
                setOpen={() => {
                    setOpen(!open);
                }}
                openColor={'#fff'}
                closedColor={'#fff'}
            /> */}
            <TopBarLogo>
                <BrandImage
                    src={'/assets/images/logo512.png'}
                    alt={'logo'}
                    onClick={() => {
                        navigate('/');
                    }}
                />
            </TopBarLogo>
            {!!headerComponent ? headerComponent : <div />}
            <UserProfileContainer>
                <UserProfileMenu logout={logout} />
            </UserProfileContainer>
        </Container>
    );
};

export default Header;

const Container = styled('div', {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateAreas: '"logo header logout"',
    gridTemplateColumns: 'auto minmax(0, 1fr) 290px',
    backgroundColor: '$primary',
    userSelect: 'none',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.15)',
    zIndex: 2,
});

const UserProfileContainer = styled('div', {
    width: '100%',
    height: '100%',
    gridArea: 'logout',
    borderLeft: '2px solid rgba(255, 255, 255, 0.2)',
});

const TopBarLogo = styled('div', {
    height: '100%',
    gridArea: 'logo',
    display: 'grid',
    alignItems: 'center',
    padding: '0 $4',
    borderLeft: '2px solid rgba(255, 255, 255, 0.2)',
    userSelect: 'none',
    userDrag: 'none',
    MozUserSelect: 'none',
    WebkitUserDrag: 'none',
    WebkitUserSelect: 'none',
});

const BrandImage = styled('img', {
    height: 50,
    filter: 'grayscale(1) brightness(100)',
});
