import React from 'react';
import { HeaderContainer } from './ProjectStyledComponents';
import ExpandableEntry from './ExpandableEntry';

const RenderDeviceResults = ({ type, list, setEditPayload, controls }) => {
    if (type !== 'device') {
        return null;
    }

    return (
        <>
            <HeaderContainer type={'device'}>
                <h3>Mobile number</h3>
                <h3>Callsign</h3>
                <h3>Base</h3>
                <h3>Province</h3>
                <h3>Authorized</h3>
                {controls}
            </HeaderContainer>
            <div
                style={{
                    height: 'calc(100vh - 320px)',
                    overflow: 'auto',
                    width: '100%',
                    padding: '0 20px',
                }}
            >
                {list?.map((device, index) => (
                    <ExpandableEntry
                        key={device?.cellphone_number || index}
                        type={'device'}
                        item={device}
                        setPayload={setEditPayload}
                    />
                ))}
            </div>
        </>
    );
};

export default React.memo(RenderDeviceResults);
