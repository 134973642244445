import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const resetDevice = async (setLoading, setOpen, mobileNumber, callSign, type) => {
    if (setLoading) setLoading(true);
    const url = `${API_URL}/Device/${type === 'revoke' ? 'revoke_auth' : 'reset'}`;
    const payload = {
        call_sign: callSign,
        mobile_number: mobileNumber,
    };

    axios
        .post(url, payload)
        .then((r) => {
            engine.broadcastMessage('snackbar', {
                type: 'success',
                message: `Successfully ${
                    type === 'update_callsign'
                        ? 'updated callsign'
                        : type === 'revoke'
                        ? 'revoked authorization'
                        : 'sent link'
                }`,
            });
            if (setOpen) setOpen(false);
        })
        .catch((err) => {
            engine.broadcastMessage('snackbar', {
                type: 'error',
                message: err.response.data || 'An error has occurred',
            });
        })
        .finally(() => {
            if (setLoading) setLoading(false);
        });
};

export const derosterEmployee = async (setLoading, setOpen, employeeNumber) => {
    if (setLoading) setLoading(true);
    const url = `${API_URL}/Employee/deroster`;
    const payload = {
        employee_number: employeeNumber,
    };

    axios
        .post(url, payload)
        .then((r) => {
            engine.broadcastMessage('snackbar', {
                type: 'success',
                message: `Successfully derostered employee`,
            });
            if (setOpen) setOpen(false);
        })
        .catch((err) => {
            engine.broadcastMessage('snackbar', {
                type: 'error',
                message: err.response.data || 'An error has occurred',
            });
        })
        .finally(() => {
            if (setLoading) setLoading(false);
        });
};
