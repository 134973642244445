import React, { useState } from 'react';
import DeviceEntry from './DeviceEntry';
import { Button } from '@a2d24-ui/button/src';
import { EntryContainer, StyledChevron } from './ProjectStyledComponents';
import EmployeeEntry from './EmployeeEntry';
import VehicleEntry from './VehicleEntry';
import { derosterEmployee, resetDevice } from '../utils/api';

const ExpandableEntry = ({ type, item, setPayload }) => {
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <EntryContainer type={type}>
            {type === 'device' && <DeviceEntry device={item} />}
            {type === 'employee' && <EmployeeEntry employee={item} />}
            {type === 'vehicle' && <VehicleEntry vehicle={item} />}
            <Button
                variant="text"
                css={{
                    alignItems: 'center',
                    display: 'flex',
                    width: 'fit-content',
                    justifySelf: 'end',
                }}
                onClick={() => setExpanded(!expanded)}
            >
                <StyledChevron data-state={expanded ? 'open' : 'closed'} />
            </Button>
            {expanded && (
                <div
                    style={{
                        gridColumn: '1 / -1',
                        display: 'flex',
                        columnGap: '20px',
                        borderTop: '0.5px solid rgba(0,0,0,0.1)',
                        padding: '10px 0 5px',
                    }}
                >
                    {type === 'device' && (
                        <>
                            <Button
                                css={{ width: 'fit-content' }}
                                onClick={() => setPayload({ ...item, popupType: 'Reassign' })}
                            >
                                Edit details
                            </Button>
                            <Button
                                css={{ width: 'fit-content' }}
                                onClick={() =>
                                    resetDevice(
                                        setLoading,
                                        null,
                                        item?.cellphone_number,
                                        item?.call_sign,
                                        'send_link'
                                    )
                                }
                            >
                                {loading ? 'Sending...' : 'Re-send SMS'}
                            </Button>
                            {item?.authorized === 'Yes' && (
                                <Button
                                    css={{ width: 'fit-content' }}
                                    onClick={() =>
                                        resetDevice(
                                            setLoading,
                                            null,
                                            item?.cellphone_number,
                                            item?.call_sign,
                                            'revoke'
                                        )
                                    }
                                >
                                    {loading ? 'Revoking...' : 'Revoke authorization'}
                                </Button>
                            )}
                        </>
                    )}
                    {type === 'employee' && (
                        <>
                            <Button css={{ width: 'fit-content' }} onClick={() => setPayload(item)}>
                                Edit details
                            </Button>
                            {item?.clock_in_vehicle && (
                                <Button
                                    css={{ width: 'fit-content' }}
                                    onClick={() =>
                                        derosterEmployee(setLoading, null, item?.employee_number)
                                    }
                                >
                                    {loading ? 'Derostering...' : 'Deroster'}
                                </Button>
                            )}
                        </>
                    )}
                    {type === 'vehicle' && (
                        <>
                            <Button css={{ width: 'fit-content' }} onClick={() => setPayload(item)}>
                                Edit details
                            </Button>
                        </>
                    )}
                </div>
            )}
        </EntryContainer>
    );
};

export default React.memo(ExpandableEntry);
