import React from 'react';

import { Navigate } from 'react-router-dom';
import { observer } from '@a2d24/emr-state/mobx-react-lite';
import { styled } from '@a2d24-ui/theme';

import { getEngine } from '@a2d24/react-engine';
import { useStores } from '@a2d24/emr-state/stores';

import TextField from '@a2d24-ui/textfield';
import BaseDialog from '../components/BaseDialog';
import TermsAndConditions from '../components/TermsAndConditions';
import Button from '../components/Button';
import ForgotPasswordWidget from './ForgotPassword';

const engine = getEngine();

const Logo = engine.providers('Logo', () => () => null)();
const LogoIcon = engine.providers('LogoIcon', () => () => null)();
const SecondaryLogo = engine.providers('SecondaryLogo', () => () => null)();

export const NETCARE_REALM = 'Netcare-Users';
export const AUTH0_REALM = 'Username-Password-Authentication';

const APP_VERSION = process.env.REACT_APP_VERSION;

const Login = observer(() => {
    const { mfaStore } = useStores();

    const {
        username,
        password,
        requestInFlight,
        errorMessage,
        realm,
        mfaPending,
        mfaAuthenticatorsFetched,
        authenticated,
        failedLoginAttempts,
    } = mfaStore;

    const [loginClicked, setLoginClicked] = React.useState(false);
    const [resetPasswordOpen, setResetPasswordOpen] = React.useState(false);
    const [termsOpen, setTermsOpen] = React.useState(false);

    React.useEffect(() => {
        mfaStore.setErrorMessage(null);
    }, []);

    const renderButtonText = (realm) =>
        realm === NETCARE_REALM
            ? 'Sign in with Netcare Digital'
            : 'Sign in with Netcare / Medicross';

    const renderBannerText = (realm) =>
        realm === NETCARE_REALM
            ? 'Login using your Netcare or Medicross credentials'
            : 'Login using your Netcare Digital account';

    const renderForgotPassword = () => {
        return (
            resetPasswordOpen && (
                <ForgotPasswordWidget open={resetPasswordOpen} setOpen={setResetPasswordOpen} />
            )
        );
    };

    const toggleRealm = () =>
        mfaStore.setRealm(realm === NETCARE_REALM ? AUTH0_REALM : NETCARE_REALM);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoginClicked(true);
        mfaStore.login();
    };

    if (mfaPending && mfaAuthenticatorsFetched) {
        return <Navigate to="./mfa" />;
    }

    if (authenticated) {
        return <Navigate to="/" />;
    }

    const warningMessageLoginAttempts = () => {
        if (failedLoginAttempts > 2)
            return 'Warning! You have entered an incorrect password more than 2 times. Please wait 15 minutes before logging in to avoid being blocked';
        else return '';
    };

    return (
        <LoginContainer>
            <StyledLogo />
            <LoginForm onSubmit={handleSubmit} data-show-divider="yes" data-show-helper-text="yes">
                <TextField
                    id="username"
                    label={realm === AUTH0_REALM ? 'Email' : 'Netcare / Medicross Username'}
                    value={username || ''}
                    onChange={(e) => mfaStore.setUsername(e.target.value)}
                    variant="large"
                    inputCss={{
                        '&:focus': {
                            background: '$primary4',
                        },
                    }}
                    labelCss={{
                        fontSize: '1em',
                    }}
                    state={loginClicked && !username ? 'error' : null}
                    autoFocus
                />
                <TextField
                    id="password"
                    label="Password"
                    type="password"
                    value={password || ''}
                    onChange={(e) => mfaStore.setPassword(e.target.value)}
                    variant="large"
                    inputCss={{
                        '&:focus': {
                            background: '$primary4',
                        },
                    }}
                    labelCss={{
                        fontSize: '1em',
                    }}
                    state={loginClicked && !password ? 'error' : null}
                />
                {errorMessage && (
                    <p className="error-message">
                        {errorMessage}
                        {warningMessageLoginAttempts()}
                    </p>
                )}
                <ResetPasswordLink
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setResetPasswordOpen(true);
                    }}
                >
                    {mfaStore.realm === NETCARE_REALM ? 'Trouble signing in?' : 'Forgot password?'}
                </ResetPasswordLink>
                <Button
                    css={{ marginTop: '1ch' }}
                    type="submit"
                    disabled={requestInFlight || !password}
                    onClick={handleSubmit}
                >
                    Sign in
                </Button>
                {renderForgotPassword()}
            </LoginForm>
            <div style={{ position: 'fixed', bottom: '5px', left: '5px' }}>
                Version: {APP_VERSION}
            </div>
        </LoginContainer>
    );
});

export default Login;

export const LoginContainer = styled('div', {
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '.logo-text': {
        fontWeight: 400,
        fontSize: '1.25em',
        fontColor: '$primary5',
        textTransform: 'lowercase',
        marginBottom: '2ch',
        userSelect: 'none',
    },
});

export const StyledLogo = styled(Logo, {
    width: '25ch',
    paddingBottom: '2ch',
    // transform: 'translateX(11.7%)',
    userDrag: 'none',
    MozUserSelect: 'none',
    WebkitUserDrag: 'none',
    WebkitUserSelect: 'none',
});

export const LoginForm = styled('form', {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '45ch',
    width: '90vw',

    // '&[data-show-divider="yes"] button:first-of-type': {
    //     position: 'relative',
    //     '&::after': {
    //         content: '',
    //         position: 'absolute',
    //         left: 0,
    //         right: 0,
    //         bottom: '-1.5rem',
    //         height: 1,
    //         background: '$separatorColor',
    //         pointerEvents: 'none',
    //     },
    // },

    // '&[data-show-helper-text="yes"] button:first-of-type': {
    //     position: 'relative',
    //     '&::before': {
    //         content: 'or',
    //         display: 'block',
    //         color: '$separatorColor',
    //         position: 'absolute',
    //         padding: '0 2ch',
    //         textAlign: 'center',
    //         transform: 'translateY(50%)',
    //         bottom: '-1.5rem',
    //         background: 'white',
    //         fontSize: '0.75em',
    //         zIndex: 1,
    //         pointerEvents: 'none',
    //     },
    // },

    '.error-message': {
        border: '1px solid $warning',
        borderRadius: '$2',
        padding: '2ch',
        color: '$warning',
        textAlign: 'center',
    },

    '.terms': {
        marginTop: '1.5ch',
        fontSize: '0.75em',
        color: '$lightText1',
        textAlign: 'center',
        a: {
            fontWeight: 300,
            color: '$primary5',
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
});

const AuthSelectorButton = styled(Button, {
    marginTop: '1ch',
    display: 'grid',
    gap: '2ch',
    padding: 0,
    height: '2.5em',
    gridTemplateColumns: '2.5em 1fr',
    fontSize: '1em',
    div: {
        width: '100%',
        height: '100%',
        borderRadius: 4,
        display: 'grid',
        placeItems: 'center',
        img: {
            maxWidth: 25,
            maxHeight: 25,
        },
        gridColumn: '1',
        gridRow: '1',
    },
    span: {
        textAlign: 'center',
        gridColumn: '1 / -1',
        gridRow: '1',
    },
});

const ResetPasswordLink = styled('a', {
    textDecoration: 'none',
    color: '$primary',
    fontWeight: 500,
    alignSelf: 'start',
    padding: '0.2ch 0',
    marginBottom: '1ch',
    margin: '0 0 20px auto',
});
