import React from 'react';
import { Button } from '@a2d24-ui/button/src';
import { StyledChevronHorizontal } from './ProjectStyledComponents';

const PaginationControls = ({ pageIndex, setPageIndex, listStates, searchType, pageSize }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
                onClick={() => {
                    if (pageIndex > 1) setPageIndex(pageIndex - 1);
                }}
                variant="icon"
                css={{ display: 'flex', alignItems: 'center' }}
            >
                <StyledChevronHorizontal direction="left" />
            </Button>
            Page {pageIndex} of{' '}
            {Math.ceil(listStates[searchType]?.value?.length / pageSize.value || 1)}
            <Button
                onClick={() => {
                    if (pageIndex < listStates[searchType]?.value?.length / pageSize.value)
                        setPageIndex(pageIndex + 1);
                }}
                variant="icon"
                css={{ display: 'flex', alignItems: 'center' }}
            >
                <StyledChevronHorizontal direction="right" />
            </Button>
        </div>
    );
};

export default React.memo(PaginationControls);
