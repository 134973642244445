import React from 'react';
import { HeaderContainer } from './ProjectStyledComponents';
import ExpandableEntry from './ExpandableEntry';

const RenderVehicleResults = ({ render, list, setEditPayload, controls }) => {
    if (!render) {
        return null;
    }

    return (
        <>
            <HeaderContainer type={'vehicle'}>
                <h3>Callsign</h3>
                <h3>Status</h3>
                <h3>Reason</h3>
                <h3>Location</h3>
                <h3>Last seen</h3>
                {controls}
            </HeaderContainer>
            <div
                style={{
                    height: 'calc(100vh - 310px)',
                    overflow: 'auto',
                    width: '100%',
                    padding: '0 20px',
                }}
            >
                {list?.map((vehicle, index) => (
                    <ExpandableEntry
                        key={vehicle?.call_sign || index}
                        type={'vehicle'}
                        item={vehicle}
                        setPayload={setEditPayload}
                    />
                ))}
            </div>
        </>
    );
};

export default React.memo(RenderVehicleResults);
