// import logo from './public/assets/images/netcareLogoFull.png';
import logo from './public/assets/images/logo512.png';
import secondary_logo from './public/assets/images/logo512.png';
// import icon_white from './public/assets/images/netcare-digital-icon-white.svg';
import icon from './public/assets/images/favicon.ico';

import { styled } from '@a2d24-ui/theme';

const StyledLogo = styled('img', {}); // For css based styling

const Logo = (props) => <StyledLogo src={logo} alt="logo" {...props} />;
export const Icon = (props) => <StyledLogo src={icon} alt="icon" {...props} />;
export const SecondaryLogo = (props) => <StyledLogo src={secondary_logo} alt="logo" {...props} />;

export default Logo;
