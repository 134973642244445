const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
];

export const timeSince = (date) => {
    if (!date) return '';
    const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    if (seconds <= 0) return 'now';
    const interval = intervals.find((i) => (i?.seconds || 0) <= seconds);
    const count = Math.floor(seconds / interval.seconds);
    if (interval.label === 'second') return 'Less than a minute ago';
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
};
