import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const ComputerSearchIcon = styled(
    React.forwardRef(({ ...props }, forwardedRef) => (
        <svg width="129" height="79" {...props} version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <path id="a" d="M0 0h110.288v2.708H0z" />
                <path id="c" d="M0 0h89.045v2.708H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M109.558 67.406H22.484a2.23 2.23 0 0 1-2.228-2.323l2.06-62.76C22.358 1.046 23.43 0 24.696 0h87.075a2.231 2.231 0 0 1 2.228 2.323l-2.06 62.76c-.043 1.278-1.114 2.323-2.381 2.323"
                    fill="#E0E0E0"
                />
                <path
                    d="M108.847 67.406H21.773a2.23 2.23 0 0 1-2.228-2.323l2.06-62.76C21.647 1.046 22.718 0 23.985 0h87.075a2.231 2.231 0 0 1 2.228 2.323l-2.06 62.76c-.043 1.278-1.114 2.323-2.381 2.323"
                    fill="#4C536B"
                    opacity=".02"
                />
                <g transform="translate(0 67.292)">
                    <mask id="b" fill="#fff"></mask>
                    <path
                        d="M107.725 0H2.563A2.754 2.754 0 0 0 .078 1.566a.8.8 0 0 0 .727 1.142h108.678a.8.8 0 0 0 .727-1.142A2.753 2.753 0 0 0 107.725 0"
                        fill="#E0E0E0"
                        mask="url(#b)"
                    />
                </g>
                <g transform="translate(0 67.292)">
                    <mask id="d" fill="#fff"></mask>
                    <path
                        d="M2.563 0h83.919c1.095 0 2.046.643 2.484 1.566a.8.8 0 0 1-.727 1.142H.806a.8.8 0 0 1-.728-1.142A2.753 2.753 0 0 1 2.563 0"
                        fill="#4C536B"
                        opacity=".02"
                        mask="url(#d)"
                    />
                </g>
                <path
                    d="M52.726 69.014H30.893a.985.985 0 0 0-.978.986h23.788a.985.985 0 0 0-.977-.986"
                    fill="#7D86A9"
                    opacity=".141"
                />
                <path
                    d="m21.625 62.814 1.912-58.22a.94.94 0 0 1 .927-.906h85.875a.87.87 0 0 1 .869.905l-1.912 58.22a.94.94 0 0 1-.927.906H22.493a.87.87 0 0 1-.868-.905"
                    fill="#FFF"
                />
                <path
                    d="m23.9 60.336 1.751-53.34a1.13 1.13 0 0 1 1.125-1.098h81.031c.637 0 1.147.532 1.126 1.173l-1.752 53.34a1.13 1.13 0 0 1-1.125 1.097h-81.03a1.132 1.132 0 0 1-1.127-1.172"
                    fill="#7D86A9"
                    opacity=".102"
                />
                <path
                    fill="#FFF"
                    d="M90.003 63.72h14.236L87.833 3.689H73.597zM80.935 63.72h5.122L69.651 3.689H64.53zM51.786 63.72h5.122L40.502 3.689H35.38zM68.134 38.049a.706.706 0 0 1-.697.68.652.652 0 0 1-.653-.68.706.706 0 0 1 .697-.681c.373 0 .665.305.653.68"
                />
                <g>
                    <path
                        d="M85.561 64.792c-8.073-8.073-8.073-21.161-.002-29.232 8.073-8.073 21.16-8.073 29.233 0 8.07 8.071 8.07 21.159-.002 29.232-8.071 8.07-21.158 8.07-29.229 0m43.38 10.548a2.203 2.203 0 0 0-.451-.97c-.459-.49-.948-.949-1.429-1.415a62.705 62.705 0 0 0-1.474-1.37c-.494-.454-1.002-.894-1.51-1.335a61.514 61.514 0 0 0-3.158-2.53 49.997 49.997 0 0 0-1.51-1.079 1.832 1.832 0 0 0-.423-1.928l-.747-.746a1.832 1.832 0 0 0-.442-.327c6.66-8.694 6.015-21.19-1.94-29.145-8.66-8.66-22.704-8.66-31.363 0-8.66 8.66-8.658 22.702.002 31.362 7.955 7.955 20.45 8.601 29.144 1.94.084.159.193.308.327.442l.746.747a1.833 1.833 0 0 0 1.928.423c.351.512.712 1.014 1.08 1.51a61.418 61.418 0 0 0 2.53 3.158c.44.508.88 1.016 1.334 1.51.448.5.906.99 1.37 1.474.466.481.925.97 1.415 1.429.26.212.576.371.97.452.397.076.851.096 1.478-.056a2.818 2.818 0 0 0 2.068-2.069c.152-.626.132-1.08.056-1.477"
                        fill="#407BFF"
                    />
                    <path
                        d="M85.561 64.792c-8.073-8.073-8.073-21.161-.002-29.232 8.073-8.073 21.16-8.073 29.233 0 8.07 8.071 8.07 21.159-.002 29.232-8.071 8.07-21.158 8.07-29.229 0m43.38 10.548a2.203 2.203 0 0 0-.451-.97c-.459-.49-.948-.949-1.429-1.415a62.705 62.705 0 0 0-1.474-1.37c-.494-.454-1.002-.894-1.51-1.335a61.514 61.514 0 0 0-3.158-2.53 49.997 49.997 0 0 0-1.51-1.079 1.832 1.832 0 0 0-.423-1.928l-.747-.746a1.832 1.832 0 0 0-.442-.327c6.66-8.694 6.015-21.19-1.94-29.145-8.66-8.66-22.704-8.66-31.363 0-8.66 8.66-8.658 22.702.002 31.362 7.955 7.955 20.45 8.601 29.144 1.94.084.159.193.308.327.442l.746.747a1.833 1.833 0 0 0 1.928.423c.351.512.712 1.014 1.08 1.51a61.418 61.418 0 0 0 2.53 3.158c.44.508.88 1.016 1.334 1.51.448.5.906.99 1.37 1.474.466.481.925.97 1.415 1.429.26.212.576.371.97.452.397.076.851.096 1.478-.056a2.818 2.818 0 0 0 2.068-2.069c.152-.626.132-1.08.056-1.477"
                        fill="#499BAD"
                    />
                    <path d="m116.407 69.556 3.149-3.149s-.837 2.398-3.149 3.149" fill="#000" />
                    <path
                        d="M119.572 37.562a22.467 22.467 0 0 0-3.165-3.764l.138-.131a22.647 22.647 0 0 1 3.192 3.794l-.165.1zm1.694 3.03a21.961 21.961 0 0 0-.901-1.751l.17-.09c.329.572.634 1.167.91 1.767l-.18.075z"
                        fill="#407BFF"
                    />
                    <path
                        d="M119.572 37.562a22.467 22.467 0 0 0-3.165-3.764l.138-.131a22.647 22.647 0 0 1 3.192 3.794l-.165.1zm1.694 3.03a21.961 21.961 0 0 0-.901-1.751l.17-.09c.329.572.634 1.167.91 1.767l-.18.075z"
                        fill="#000"
                    />
                </g>
            </g>
        </svg>
    )),
    {}
);
