import * as Sentry from '@sentry/react';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import axios from 'axios';
import semverLt from 'semver/functions/lt';

const API_URL = process.env.REACT_APP_API_ENDPOINT;
const APP_VERSION = process.env.REACT_APP_VERSION;

export const EMR_PREFERRED_ROLE_STORAGE_KEY = 'EMR-PreferredRole'; // For localstorage

export default class AuthStore {
    emrPracticeId = null;
    authenticated = false;
    user = null;
    idToken = null;
    accessToken = null;
    emrToken = null;
    appToken = null;
    emrPractitionerSummary = null;
    typesenseToken = null;
    appVersion = APP_VERSION;
    shouldUpdateFrontend = false;

    axiosInterceptors = null;
    siteDatasetIdMapping = {};
    datasetIdToSiteMapping = {};
    roleConfiguration = {};

    authorizing = false;
    authorizingErrorMessage = null;
    authorized = false;

    displayName = null;
    availableSites = [];
    selectedSite = null;
    availableRoles = [];
    selectedRole = null;
    roleMap = {};

    initialUserProfile = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);

        reaction(
            () => this.authorized,
            () => {
                if (this.authorized) {
                    engine.triggerLifecycleEvent('onLogin');
                } else {
                    engine.triggerLifecycleEvent('onLogout');
                }
            }
        );

        reaction(
            () => this.authenticated,
            (authenticated) => {
                if (authenticated) {
                    this.setupAutoLogout();
                    this.updateCredentials();
                }
            }
        );

        // reaction(
        //     () => this.selectedRole,
        //     (newRole) => {
        //         this.setupSentryContext();
        //         // Update axios interceptors
        //         if (this.axiosInterceptors) {
        //             axios.interceptors.request.eject(this.axiosInterceptors);
        //         }
        //         this.axiosInterceptors = axios.interceptors.request.use(
        //             (request) => {
        //                 if (request.url.startsWith(API_URL)) {
        //                     request.headers['Authorization'] = `Bearer ${this.emrToken}`;
        //                     request.headers['Content-Type'] = 'application/json';
        //                     request.headers['X-EmrAssumedRole'] = this.selectedRole.identifier;
        //                 }

        //                 return request;
        //             },
        //             (error) => {
        //                 return Promise.reject(error);
        //             }
        //         );
        //     }
        // );

        reaction(
            () => this.appToken,
            (token) => {
                // Update axios interceptors
                if (this.axiosInterceptors) {
                    axios.interceptors.request.eject(this.axiosInterceptors);
                }
                this.axiosInterceptors = axios.interceptors.request.use(
                    (request) => {
                        if (request.url.startsWith(API_URL)) {
                            request.headers['Authorization'] = `Bearer ${this.appToken}`;
                            request.headers['Content-Type'] = 'application/json';
                        }

                        return request;
                    },
                    (error) => {
                        return Promise.reject(error);
                    }
                );
            }
        );

        this.logoutTimer = null;
    }

    setupSentryContext() {
        Sentry.setContext('user', {
            name: this.displayName,
            userId: this.user?.identifier,
            availableRoles: this.availableRoles,
            availableSites: this.availableSites,
            selectedSite: this.selectedSite?.identifier,
            selectedRole: this.selectedRole?.identifier,
        });

        Sentry.setContext('system', {
            appVersion: this.appVersion,
        });
    }

    setAuthenticated(newAuthenticatedValue) {
        this.authenticated = newAuthenticatedValue;

        if (newAuthenticatedValue === false) {
            this.reset();
        }
    }

    updateCredentials() {
        const newAccessToken = this.rootStore.mfaStore.getAccessToken();
        this.accessToken = newAccessToken;

        if (newAccessToken) {
            this.onAccessTokenAvailable();
        }
    }

    logout() {
        this.rootStore.mfaStore.logout();
        this.clearAutoLogout();
    }

    reset() {
        this.authenticated = false;
        this.user = null;
        this.idToken = null;
        this.accessToken = null;
        this.emrToken = null;
        this.appToken = null;
        this.emrPracticeId = null;
        this.typesenseToken = null;
        this.shouldUpdateFrontend = false;

        this.siteDatasetIdMapping = {};
        this.datasetIdToSiteMapping = {};
        this.authorizing = false;
        this.authorized = false;
        this.authorizingErrorMessage = null;

        this.initialUserProfile = null;

        // Eject any previous interceptors
        if (this.axiosInterceptors) {
            axios.interceptors.request.eject(this.axiosInterceptors);
            this.axiosInterceptors = null;
        }
    }

    updatePractitionerSummary(newSummary) {
        this.emrPractitionerSummary = newSummary;
        this.displayName = this.emrPractitionerSummary.display_name;
        this.availableSites = this.emrPractitionerSummary.organizations;

        this.roleMap = {};

        for (const site of this.availableSites) {
            for (const role of site.roles) {
                this.roleMap[role.identifier] = { role, site };
            }
        }

        const preferredRole = localStorage.getItem(EMR_PREFERRED_ROLE_STORAGE_KEY);
        this.setSelectedRole(preferredRole);
    }

    setSelectedRole = (identifier) => {
        // When a valid identifier is provided, apply it, otherwise default to the first available role
        if (identifier && this.roleMap[identifier]) {
            this.selectedSite = this.roleMap[identifier].site;
            this.selectedRole = this.roleMap[identifier].role;

            this.emrPracticeId = this.selectedSite.identifier;
            this.availableRoles = this.selectedSite.roles;

            localStorage.setItem(EMR_PREFERRED_ROLE_STORAGE_KEY, this.selectedRole.identifier);
        } else {
            this.setSelectedSite(this.availableSites.length > 0 ? this.availableSites[0] : null);
        }
    };

    setSelectedSite = (newSite) => {
        this.selectedSite = newSite;
        if (newSite !== null) {
            this.emrPracticeId = newSite.identifier;
            this.availableRoles = newSite.roles;
            if (newSite.roles.length > 0) {
                const selected_role_value = this.selectedRole?.role?.value;
                if (selected_role_value) {
                    const newRole = newSite.roles.find(
                        (siteRole) => siteRole.role.value === selected_role_value
                    );
                    this.selectedRole = newRole ? newRole : newSite.roles[0];
                } else this.selectedRole = newSite.roles[0];
            } else this.selectedRole = null;
            localStorage.setItem(EMR_PREFERRED_ROLE_STORAGE_KEY, this.selectedRole?.identifier);
        } else {
            this.emrPracticeId = null;
            this.availableRoles = [];
            this.selectedRole = null;
        }
    };

    shouldUpdateVersion(frontendLatestVersion = '1.0.0') {
        if (semverLt(this.appVersion, frontendLatestVersion)) this.shouldUpdateFrontend = true;
    }

    async onAccessTokenAvailable() {
        try {
            this.authorizing = true;
            const response = await axios.post(`${API_URL}/Authentication/authorize`, {
                token: this.accessToken,
                version: APP_VERSION,
            });

            runInAction(() => {
                const { token, version, display_name } = response.data;
                // this.user = practitioner_summary;
                this.appToken = token;
                this.displayName = display_name;
                // this.updatePractitionerSummary(practitioner_summary);
                // this.typesenseToken = typesense_token;
                // this.shouldUpdateVersion(frontend_min_version);
                // this.roleConfiguration = role_configuration;
                // this.siteDatasetIdMapping = site_dataset_id_mappings;
                // this.datasetIdToSiteMapping = Object.keys(site_dataset_id_mappings).reduce(
                //     (ret, key) => {
                //         ret[site_dataset_id_mappings[key]] = key;
                //         return ret;
                //     },
                //     {}
                // );

                // [IM]: The order of the next line is important. It needs to occur after `updatePractitionerSummary`
                // to ensure that the axios interceptor is set up before migrations occur (eg favourites migration)
                // this.initialUserProfile = user_profile;

                this.authorized = true;
                // this.setupSentryContext();
            });
        } catch (e) {
            runInAction(() => {
                if (e?.response?.status === 401 || e?.response?.status === 403) {
                    this.authorizingErrorMessage = e.response.data;
                } else {
                    console.error(e?.message.status, e?.message.data);
                    this.authorizingErrorMessage =
                        'Unable to authorize your account. If you do have access to the system, please try logging out logging in again.';
                }
            });
        }

        runInAction(() => {
            this.authorizing = false;
        });
    }

    setupAutoLogout() {
        const access_token = this.rootStore.mfaStore.getAccessToken(true);
        if (!access_token) return;

        const autoLogoutInMs = access_token.exp * 1000 - Date.now();

        this.logoutTimer = setTimeout(() => {
            this.logout();
        }, Math.max(0, autoLogoutInMs));

        console.debug(
            `[Auth] Auto Logout has been set [in ${Math.floor(autoLogoutInMs / 1000)} seconds]`
        );
    }

    clearAutoLogout() {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
            console.debug(`[Auth] Auto Logout has been cleared`);
        }
        this.logoutTimer = null;
    }
}
