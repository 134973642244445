const SOCKET_CONNECTING = 0;
const SOCKET_OPEN = 1;

export default class Socket {
    constructor(onChange, onMessage, onDisconnect, onError) {
        this.onChange = onChange;
        this.onMessage = onMessage;
        this.onDisconnect = onDisconnect;
        this.onError = onError;
        this.socket = null;
        this.shouldConnect = false;
        this.timeout = 125;
        this.url = null;
    }

    _reconnectIfRequired = () => {
        if (!this.shouldConnect) return;
        const self = this;
        setTimeout(() => {
            if (!this.shouldConnect) return;
            this.connect(self.url);
        }, Math.min(5000, (this.timeout += this.timeout)));
    };

    connect = (url) => {
        if (!url) return;
        const self = this;
        this.shouldConnect = true;
        this.url = url;

        if (
            this.socket &&
            (this.socket.readyState === SOCKET_OPEN || this.socket.readyState === SOCKET_CONNECTING)
        )
            return;

        this.socket = new WebSocket(url);

        this.socket.onopen = (event) => {
            this.timeout = 125;
            self.onChange(true);
        };

        this.socket.onerror = (event) => {
            self.onChange(false);
            self.onError(event);
            self._reconnectIfRequired();
        };

        this.socket.onclose = (event) => {
            self.onChange(false);
            self.onDisconnect();
            self._reconnectIfRequired();
        };

        this.socket.onmessage = (event) => {
            self.onMessage(JSON.parse(event.data));
        };
    };

    sendMessage = (message) => {
        if (this.socket && this.socket.readyState === SOCKET_OPEN) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.error('Cannot emit socket messages. WebSocket not connected.');
        }
    };

    disconnect = () => {
        this.socket.close();
        this.shouldConnect = false;
        this.url = null;
    };
}
