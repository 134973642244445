import React, { useEffect, useState } from 'react';
import { styled } from '@a2d24-ui/theme';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogTitle,
    IconButton,
    StyledCross2Icon,
} from '@a2d24-ui/dialog';

const useDialog = () => {
    const [open, setOpen] = useState(false);
    const [payload, setPayload] = useState({});
    const [Content, setContent] = useState(null);

    const resetDialog = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setPayload(null);
        setOpen(false);
    };

    return {
        wrapper: (props) => (
            <Dialog modal={true} open={open}>
                <StyledContent>
                    {open && <Content {...props} {...payload} setOpen={setOpen} />}
                    <DialogClose asChild>
                        <StyledCloseButton onClick={resetDialog}>
                            <StyledCross2Icon />
                        </StyledCloseButton>
                    </DialogClose>
                </StyledContent>
            </Dialog>
        ),
        setOpen: setOpen,
        setContent: setContent,
        setPayload: setPayload,
    };
};

export default useDialog;

const StyledCloseButton = styled(IconButton, {
    width: 35,
    height: 35,
    position: 'absolute',
    top: 10,
    right: 10,
});

const StyledContent = styled(DialogContent, {
    width: '65vw',
    maxWidth: '65vw',
    height: '85vh',
    maxHeight: '85vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5em',
});
