import React from 'react';
import { HeaderContainer } from './ProjectStyledComponents';
import ExpandableEntry from './ExpandableEntry';

const RenderEmployeeResults = ({ render, list, setEditPayload, controls }) => {
    if (!render) {
        return null;
    }

    return (
        <>
            <HeaderContainer type={'employee'}>
                <h3>Employee number</h3>
                <h3>Name</h3>
                <h3>Surname</h3>
                <h3>Active callsign</h3>
                <h3>Last rostered</h3>
                {controls}
            </HeaderContainer>
            <div
                style={{
                    height: 'calc(100vh - 310px)',
                    overflow: 'auto',
                    width: '100%',
                    padding: '0 20px',
                }}
            >
                {list?.map((employee, index) => (
                    <ExpandableEntry
                        key={employee?.employee_number || index}
                        type={'employee'}
                        item={employee}
                        setPayload={setEditPayload}
                    />
                ))}
            </div>
        </>
    );
};

export default React.memo(RenderEmployeeResults);
