import React from 'react';

const DeviceEntry = ({ device }) => {
    return (
        <>
            <div>{device?.cellphone_number || `-`}</div>
            <div>{device?.call_sign || `-`}</div>
            <div>{device?.base || `-`}</div>
            <div>{device?.province || `-`}</div>
            <div>{device?.authorized || `-`}</div>
        </>
    );
};

export default React.memo(DeviceEntry);
