import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@a2d24-ui/theme';
import { useStores } from '@a2d24/emr-state/stores';
import { observer } from '@a2d24/emr-state/mobx-react-lite';

import { Button } from '@a2d24-ui/button';
import { TextInput } from '@a2d24-ui/input';
import { ButtonGroup } from '@a2d24-ui/button-group';
import { Select } from '@a2d24-ui/select/src';
import LoadingScreen from '@a2d24-ui/loading-screen';
import { IconButton } from '@a2d24-ui/dialog';
import { ReloadIcon } from '@radix-ui/react-icons';
import { ComputerSearchIcon } from '@a2d24-icons/computer-search-icon';
import useQueryState from './Hooks/useQueryState';
import useDialog from './Hooks/useDialog';
import useForms from './Hooks/useForms';
import RenderEmployeeResults from './components/RenderEmployeeResults';
import RenderVehicleResults from './components/RenderVehicleResults';
import PaginationControls from './components/PaginationControls';
import RenderDeviceResults from './components/RenderDeviceResults';

// Constants

const listOptions = [
    { label: 'Vehicle', value: 'vehicle' },
    { label: 'Employee', value: 'employee' },
    { label: 'Device', value: 'device' },
];

const searchKeys = {
    vehicle: ['call_sign', 'vehicle_status', 'vehicle_status_reason', 'management_groups'],
    employee: ['employee_number', 'name', 'surname', 'clock_in_vehicle'],
    device: ['cellphone_number', 'call_sign', 'base', 'province', 'authorized'],
};

const pageSizes = [
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '60', value: 60 },
];

const ascDescOptions = [
    { label: 'Ascending', value: 'ascending' },
    { label: 'Descending', value: 'descending' },
];

const sortOptions = {
    vehicle: [
        { label: 'Callsign', value: 'call_sign' },
        { label: 'Status', value: 'vehicle_status' },
        { label: 'Last seen', value: 'last_seen' },
    ],
    employee: [
        { label: 'Employee number', value: 'employee_number' },
        { label: 'Name', value: 'name' },
        { label: 'Surname', value: 'surname' },
        { label: 'Callsign', value: 'clock_in_vehicle' },
    ],
    device: [
        { label: 'Mobile number', value: 'cellphone_number' },
        { label: 'Callsign', value: 'call_sign' },
        { label: 'Base', value: 'base' },
        { label: 'Province', value: 'province' },
        { label: 'Authorized', value: 'authorized' },
    ],
};

const filterOptions = {
    vehicle: [
        { label: 'Available', value: 'vehicle_status:Available' },
        { label: 'Unavailable', value: 'vehicle_status:Unavailable' },
        { label: 'Has crew', value: 'crew:true' },
        { label: 'No crew', value: 'crew:false' },
    ],
    employee: [
        { label: 'Rostered', value: 'clock_in_vehicle:true' },
        { label: 'Not rostered', value: 'clock_in_vehicle:false' },
        { label: 'HPCSA', value: 'hpcsa_number:true' },
    ],
    device: [
        { label: 'Authorized', value: 'authorized:Yes' },
        { label: 'Not authorized', value: 'authorized:No' },
    ],
};

const ManagementRoute = (props) => {
    const { authStore } = useStores();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('vehicle');

    const [activeFilters, setActiveFilters] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [ascDesc, setAscDesc] = useState('ascending');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState({ label: '15', value: 15 });

    const backend = useQueryState();
    const dialog = useDialog();
    const forms = useForms();

    useEffect(() => {
        filterResults(searchType, searchTerm || '', activeFilters);
    }, [backend?.devices, backend?.vehicles, backend?.employees, searchType, sortField, ascDesc]);

    const [vehicleList, setVehicleList] = useState(backend?.vehicles);
    const [employeeList, setEmployeeList] = useState(backend?.employees);
    const [deviceList, setDeviceList] = useState(backend?.devices);

    const filterResults = (type, value, filters) => {
        const keys = searchKeys[type];
        const setItems = listStates[type].setter;
        if (setItems && keys) {
            const items = backend[`${type}s`].filter((item) => {
                if (filters) {
                    for (const [index, filter] of Object.entries(filters)) {
                        const [filter_key, filter_value] = filter.value.split(':');
                        if (
                            ['crew'].includes(filter_key) &&
                            !!Object.keys(item[filter_key]).length !== (filter_value === 'true')
                        ) {
                            return false;
                        }

                        if (
                            ['hpcsa_number', 'clock_in_vehicle'].includes(filter_key) &&
                            !!item[filter_key] !== (filter_value === 'true')
                        ) {
                            return false;
                        }

                        if (
                            !['crew', 'hpcsa_number', 'clock_in_vehicle'].includes(filter_key) &&
                            item[filter_key] !== filter_value
                        ) {
                            return false;
                        }
                    }
                }
                return keys.some((key) =>
                    (item[key] || '').toString().toLowerCase()?.includes(value.toLowerCase())
                );
                // return item[key]?.includes(value);
            });
            setItems(
                sortField
                    ? items.sort((a, b) => {
                          if (ascDesc?.value === 'ascending')
                              return a[sortField.value] > b[sortField.value] ? 1 : -1;
                          return a[sortField.value] > b[sortField.value] ? -1 : 1;
                          // ('' + a[sortField.value]).localeCompare(b[sortField.value])
                      })
                    : items
            );
        }
    };

    useEffect(() => {
        if (authStore.selectedRole)
            navigate(`/${authStore.selectedRole.role.value.toLowerCase().replace(' ', '')}/`);
    }, [authStore.selectedRole, navigate]);

    const listStates = {
        vehicle: {
            setter: setVehicleList,
            value: vehicleList,
        },
        device: {
            setter: setDeviceList,
            value: deviceList,
        },
        employee: {
            setter: setEmployeeList,
            value: employeeList,
        },
    };

    const openEdit = (payload) => {
        if (payload) {
            if (searchType === 'device') dialog?.setContent(() => forms?.reassign.device);
            dialog?.setPayload({ ...payload, vehicles: backend?.vehicles });
            dialog?.setOpen(true);
        }
    };

    const getPageIndex = () => {
        return [pageIndex * pageSize.value - pageSize.value, pageIndex * pageSize.value];
    };

    const updateContent = (searchType) => {
        if (searchType === 'vehicle') dialog?.setContent(() => forms?.register.vehicle);
        if (searchType === 'employee') dialog?.setContent(() => forms?.register.employee);
        if (searchType === 'device') dialog?.setContent(() => forms?.register.device);
    };

    useEffect(() => {
        setSortField(null);
        setActiveFilters(null);
        setSearchTerm('');
        updateContent(searchType);
    }, [searchType]);

    return (
        <Container>
            {dialog.wrapper()}
            <Container direction="row">
                <ButtonGroup
                    name={'listType'}
                    value={searchType}
                    css={{ margin: '0' }}
                    onChange={(value) => {
                        setSearchType(value);
                        setPageIndex(1);
                    }}
                    options={listOptions}
                    ensureValueSelected
                />
            </Container>
            <Container direction="row" style={{ padding: '15px 40px 0', justifyContent: 'normal' }}>
                <div style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
                    <h1 style={{ textTransform: 'capitalize' }}>{searchType}s</h1>
                    <IconButton
                        onClick={() => {
                            backend.refresh(searchType, setLoading).then(() => {
                                filterResults(searchType, searchTerm || '', activeFilters);
                                setPageIndex(1);
                            });
                        }}
                    >
                        <ReloadIcon />
                    </IconButton>
                </div>
                <TextInput
                    onInput={(value) => {
                        const searchVal = value?.target?.value;
                        setSearchTerm(searchVal);
                        filterResults(searchType, searchVal, activeFilters);
                        setPageIndex(1);
                    }}
                    value={searchTerm}
                    placeholder={'Search term'}
                    css={{ width: '300px' }}
                ></TextInput>
                <Select
                    options={sortOptions[searchType]}
                    placeholder={'Sort by'}
                    onChange={(value) => {
                        setSortField(value);
                    }}
                    value={sortField}
                />
                <Select
                    options={ascDescOptions}
                    // placeholder={'Asc/Desc'}
                    onChange={(value) => {
                        setAscDesc(value);
                    }}
                    value={ascDesc}
                />
                <Select
                    options={filterOptions[searchType]}
                    placeholder={'Filters'}
                    onChange={(value) => {
                        setActiveFilters(value);
                        filterResults(searchType, searchTerm, value);
                    }}
                    value={activeFilters}
                    isMulti
                />
                <div style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
                    Show
                    <Select
                        options={pageSizes}
                        placeholder={'Page size'}
                        onChange={(value) => {
                            setPageSize(value);
                        }}
                        value={pageSize}
                        css={{ width: '75px' }}
                    />
                    results
                </div>
                <Button
                    onClick={() => {
                        updateContent(searchType);
                        dialog?.setPayload({ vehicles: backend?.vehicles });
                        dialog?.setOpen(true);
                    }}
                >
                    Add item
                </Button>
            </Container>

            {!loading && (
                <RenderVehicleResults
                    render={searchType === 'vehicle'}
                    list={
                        vehicleList?.length > pageSize.value
                            ? vehicleList.slice(...getPageIndex())
                            : vehicleList
                    }
                    setEditPayload={openEdit}
                    controls={
                        <PaginationControls
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            listStates={listStates}
                            searchType={searchType}
                            pageSize={pageSize}
                        />
                    }
                />
            )}
            {!loading && (
                <RenderEmployeeResults
                    render={searchType === 'employee'}
                    list={
                        employeeList?.length > pageSize.value
                            ? employeeList.slice(...getPageIndex())
                            : employeeList
                    }
                    setEditPayload={openEdit}
                    controls={
                        <PaginationControls
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            listStates={listStates}
                            searchType={searchType}
                            pageSize={pageSize}
                        />
                    }
                />
            )}
            {!loading && (
                <RenderDeviceResults
                    type={searchType}
                    list={
                        deviceList?.length > pageSize.value
                            ? deviceList.slice(...getPageIndex())
                            : deviceList
                    }
                    setEditPayload={openEdit}
                    controls={
                        <PaginationControls
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            listStates={listStates}
                            searchType={searchType}
                            pageSize={pageSize}
                        />
                    }
                />
            )}
            {loading && (
                // TODO: Add the retry functionality of the request here, and error messages on failure
                <LoadingScreen
                    image={<ComputerSearchIcon css={{ marginBottom: '3ch' }} />}
                    loading={loading}
                    text={`Loading ${searchType}s...`}
                />
            )}
        </Container>
    );
};

export default observer(ManagementRoute);

const Container = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'left',
    variants: {
        direction: {
            row: {
                height: 'auto',
                width: '100%',
                display: 'grid',
                columnGap: '20px',
                gridTemplateColumns:
                    '190px 300px 200px minmax(150px, 250px) minmax(200px, 400px) auto 120px',
                padding: '25px 25px 10px',
            },
        },
    },
});
