import React from 'react';
import { styled } from '@a2d24-ui/theme';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Tooltip } from '@a2d24-ui/tooltip';

const ErrorIndicator = styled('div', {
    width: 'auto',
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'end',
    marginRight: 8,
});

const WarningIndicator = styled('div', {
    width: 'auto',
    height: 'auto',
    display: 'grid',
});

const StyledIcon = styled(ExclamationTriangleIcon, {
    color: '$warning',
    width: 20,
    height: 20,
});

export const ErrorTooltip = ({ text, className = '' }) => (
    <ErrorIndicator className={className}>
        <Tooltip trigger={<StyledIcon />} text={text} />
    </ErrorIndicator>
);

export const WarningTooltip = ({ text, className = '' }) => (
    <WarningIndicator className={className}>
        <Tooltip trigger={<StyledIcon />} text={text} />
    </WarningIndicator>
);
