import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const CloseIcon = styled(
    React.forwardRef(({ color = '#499BAD', ...props }, forwardedRef) => (
        <svg
            width="10px"
            height="10px"
            viewBox="0 0 10 10"
            {...props}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="04_Medical-History_Full"
                    transform="translate(-662.000000, -241.000000)"
                    fill={color}
                    fillRule="nonzero"
                    stroke={color}
                    strokeWidth="0.4"
                >
                    <g id="Group-10" transform="translate(609.000000, 232.000000)">
                        <g id="noun_Close_109982" transform="translate(54.000000, 10.000000)">
                            <path
                                d="M4.5829125,4 L7.87921261,0.703699887 C8.04026246,0.542787454 8.04026246,0.281699818 7.87921261,0.120787385 C7.71816277,-0.0402624618 7.45734996,-0.0402624618 7.29630011,0.120787385 L4,3.4170875 L0.703699887,0.120787385 C0.542787454,-0.0402624618 0.281699818,-0.0402624618 0.120787385,0.120787385 C-0.0402624618,0.281699818 -0.0402624618,0.542787454 0.120787385,0.703699887 L3.4170875,4 L0.120787385,7.29630011 C-0.0402624618,7.45721255 -0.0402624618,7.71830018 0.120787385,7.87921261 C0.201174894,7.95973754 0.30684668,8 0.412243636,8 C0.517640592,8 0.623174963,7.95973754 0.703699887,7.87921261 L4,4.5829125 L7.29630011,7.87921261 C7.37682504,7.95973754 7.48235941,8 7.58775636,8 C7.69315332,8 7.79868769,7.95973754 7.87921261,7.87921261 C8.04026246,7.71830018 8.04026246,7.45721255 7.87921261,7.29630011 L4.5829125,4 Z"
                                id="Path"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )),
    {}
);
