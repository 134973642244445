import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const TrashIcon = styled(
    React.forwardRef(({ color = 'currentColor', ...props }, forwardedRef) => (
        <svg
            width="16"
            height="16"
            viewBox="0 0 10 10"
            {...props}
            ref={forwardedRef}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Page-1" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
                <g
                    id="04_Medical-History_Full"
                    transform="translate(-583.000000, -241.000000)"
                    fill={color}
                    fillRule="nonzero"
                >
                    <g id="noun_Delete_744231" transform="translate(583.000000, 241.000000)">
                        <path
                            d="M9.57142857,1.17231638 L7.14285714,1.17231638 L7.14285714,0.649717514 C7.14285714,0.282485876 6.8,0 6.37142857,0 L3.62857143,0 C3.2,0 2.85714286,0.282485876 2.85714286,0.649717514 L2.85714286,1.17231638 L0.428571429,1.17231638 C0.185714286,1.17231638 0,1.3559322 0,1.5960452 C0,1.83615819 0.185714286,2.01977401 0.428571429,2.01977401 L0.957142857,2.01977401 L1.48571429,8.60169492 C1.5,9.37853107 2.12857143,10 2.91428571,10 L7.07142857,10 C7.85714286,10 8.48571429,9.37853107 8.5,8.60169492 L9.04285714,2.03389831 L9.57142857,2.03389831 C9.81428571,2.03389831 10,1.85028249 10,1.61016949 C10,1.3700565 9.81428571,1.17231638 9.57142857,1.17231638 Z M3.71428571,0.847457627 L6.28571429,0.847457627 L6.28571429,1.17231638 L3.71428571,1.17231638 L3.71428571,0.847457627 Z M3.88571429,7.7259887 C3.88571429,7.7259887 3.87142857,7.7259887 3.87142857,7.7259887 C3.64285714,7.7259887 3.45714286,7.54237288 3.44285714,7.31638418 L3.28571429,3.8700565 C3.27142857,3.6299435 3.45714286,3.43220339 3.7,3.43220339 C3.7,3.43220339 3.71428571,3.43220339 3.71428571,3.43220339 C3.94285714,3.43220339 4.12857143,3.61581921 4.14285714,3.84180791 L4.3,7.28813559 C4.3,7.51412429 4.12857143,7.71186441 3.88571429,7.7259887 Z M6.55714286,7.31638418 C6.54285714,7.54237288 6.35714286,7.7259887 6.12857143,7.7259887 C6.12857143,7.7259887 6.11428571,7.7259887 6.11428571,7.7259887 C5.87142857,7.71186441 5.7,7.51412429 5.7,7.28813559 L5.85714286,3.84180791 C5.87142857,3.61581921 6.05714286,3.43220339 6.28571429,3.43220339 C6.28571429,3.43220339 6.3,3.43220339 6.3,3.43220339 C6.54285714,3.44632768 6.71428571,3.6440678 6.71428571,3.8700565 L6.55714286,7.31638418 Z"
                            id="Shape"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )),
    {}
);
