import React from 'react';
import { timeSince } from '../utils/timeSince';

const EmployeeEntry = ({ employee }) => {
    return (
        <>
            <div>{employee?.employee_number || `-`}</div>
            <div>{employee?.name || `-`}</div>
            <div>{employee?.surname || `-`}</div>
            <div>{employee?.clock_in_vehicle || `-`}</div>
            <div>
                {(employee?.clock_in_vehicle
                    ? timeSince(employee.clock_in_time)
                    : timeSince(employee.clock_out_time)) || `-`}
            </div>
        </>
    );
};

export default React.memo(EmployeeEntry);
