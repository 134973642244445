import { makeAutoObservable, toJS } from 'mobx';

// https://www.unicode.org/cldr/cldr-aux/charts/30/supplemental/day_periods.html
export const getDayPeriod = () => {
    const hourOfDay = new Date().getHours();

    if (hourOfDay >= 0 && hourOfDay < 6) return 'night';
    if (hourOfDay >= 6 && hourOfDay < 12) return 'morning';
    if (hourOfDay >= 12 && hourOfDay < 18) return 'afternoon';

    return 'evening';
};

export default class UIStore {
    menuOpen = false;
    dayPeriod = getDayPeriod();
    accordianExpandAll = false;
    patientFileSearchTags = {};
    practiceStreamingList = [];
    manualPayment = JSON.parse(localStorage.getItem('manualPayment') || false);
    widgetsOrdering = JSON.parse(localStorage.getItem('widgetsOrdering')) || {};
    diaryDoctorFilterPreference = JSON.parse(
        localStorage.getItem('diaryDoctorFilterPreference') || '{}'
    );

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.startDayPeriodUpdate();
    }

    setOpen = (value) => {
        this.menuOpen = value;
    };

    setExpandAll = (value) => {
        this.accordianExpandAll = value;
    };

    setPatientFileSearchTag(tag, value) {
        this.patientFileSearchTags = { ...this?.patientFileSearchTags, [tag]: value };
    }

    clearPatientFileSearchTags() {
        this.patientFileSearchTags = {};
    }

    startDayPeriodUpdate = () => {
        this._timerId = setInterval(() => {
            this.updateDayPeriod();
        }, 1000 * 60);
    };

    updateDayPeriod = () => {
        this.dayPeriod = getDayPeriod();
    };

    stopDayPeriodUpdate = () => {
        if (this._timerId) clearInterval(this._timerId);
    };

    get user() {
        return this.rootStore.authStore.user;
    }

    updatePracticeLists = (newList) => {
        this.practiceStreamingList = newList;
    };

    setManualPayment = (newValue) => {
        localStorage.setItem('manualPayment', newValue);
        this.manualPayment = newValue;
    };

    setDiaryDoctorFilterPreference = (datasetId, newValue) => {
        localStorage.setItem(
            'diaryDoctorFilterPreference',
            JSON.stringify({
                ...this.diaryDoctorFilterPreference,
                [datasetId]: newValue,
            })
        );
        this.diaryDoctorFilterPreference = {
            ...this.diaryDoctorFilterPreference,
            [datasetId]: newValue,
        };
    };

    setWidgetsOrdering = (identifier, widget_ids) => {
        this.widgetsOrdering = { ...this?.widgetsOrdering, [identifier]: widget_ids };
        localStorage.setItem('widgetsOrdering', JSON.stringify(this.widgetsOrdering));
    };
}
