import React from 'react';
import { timeSince } from '../utils/timeSince';

const VehicleEntry = ({ vehicle }) => {
    return (
        <>
            <div>{vehicle?.call_sign || `-`}</div>
            <div>{vehicle?.vehicle_status || `-`}</div>
            <div>{vehicle?.vehicle_status_reason || `-`}</div>
            <div>
                {vehicle?.location ? (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.google.com/maps/search/?api=1&query=${vehicle.location.lat},${vehicle.location.lon}`}
                    >
                        Find in maps
                    </a>
                ) : (
                    `-`
                )}
            </div>
            <div>{timeSince(vehicle?.last_seen) || `-`}</div>
        </>
    );
};

export default React.memo(VehicleEntry);
