import React from 'react';
import * as Sentry from '@sentry/react';
import './error.css';

const APP_VERSION = process.env.REACT_APP_VERSION;

// This global variable keeps track of the number of times the user has attempted to resolve the error boundary condition
global.errorBoundaryRetryCount = 0;

const conditionalReloadOnMultipleRetries = (fn) => {
    global.errorBoundaryRetryCount += 1;
    // On every 2nd attempt, force a page reload to fetch the latest frontend if available
    if (global.errorBoundaryRetryCount % 2 === 0) {
        window.location.reload();
    } else {
        fn();
    }
};

const ErrorFallback = ({ error, componentStack, resetError }) => {
    return (
        <div className="error-boundary-container">
            <div className="error-boundary">
                <h1>Oops!</h1>
                <h2>There's an error</h2>
                <div className="error-boundary-message">{`Version: ${APP_VERSION} - ${error.message}`}</div>

                <div className="error-boundary-actions">
                    <a
                        className={'error-boundary-button secondary'}
                        onClick={(e) => {
                            e.preventDefault();
                            conditionalReloadOnMultipleRetries(resetError);
                        }}
                        href="#"
                    >
                        Try again
                    </a>

                    <a className={'error-boundary-button'} href="/">
                        Home
                    </a>
                </div>
            </div>
        </div>
    );
};

const withErrorBoundary = (WrappedComponent) => (props) =>
    (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <WrappedComponent {...props} />
        </Sentry.ErrorBoundary>
    );

export default withErrorBoundary;
