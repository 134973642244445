import axios from 'axios';

import { makeAutoObservable, observable, runInAction } from 'mobx';

export const API_URL = process.env.REACT_APP_API_ENDPOINT;

export default class UserProfileStore {
    profile = observable.map();
    initialized = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        // autorun(() => console.log(toJS(this.profile)));
    }

    initializeProfile(newProfile) {
        // Replace the map with a new profile, or an empty map if null
        this.profile.replace(newProfile || {});
        this.initialized = newProfile !== null;

        if (this.initialized) this.migrate();
    }

    async save(key, value) {
        if (this.profile === null) {
            console.warn('[UserProfileStore]: ignoring setValue on uninitialized user profile', {
                key,
                value,
            });
            return;
        }

        // Write through caching - set the value locally and then save to cloud
        runInAction(() => {
            this.profile.set(key, value);
        });

        try {
            const result = await axios.post(`${API_URL}/UserProfile/save`, { key, value });
        } catch (e) {
            console.error('[UserProfileStore]: Failed to save to UserProfile', e, { key, value });
        }
    }

    migrate() {
        // Migrates any legacy storage medium to a cloud synchronised user profile
        if (!this.profile.get('favourites_imported')) {
            this.importFavourites();
        }
    }

    async importFavourites() {
        const preferencesJSON = localStorage.getItem('preferences');
        const preferences = preferencesJSON ? JSON.parse(preferencesJSON) : {};
        const favourites = preferences?.favourites || {};

        await this.save('favourites', favourites);
        await this.save('favourites_imported', true);
    }
}
