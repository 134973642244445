import React from 'react';
import { CheckboxV2 } from './CheckboxV2';

export const Checkbox = ({ name, value, text, input, ...rest }) => {
    return (
        <CheckboxV2
            id={name}
            checked={input?.value}
            onCheckedChange={input?.onChange}
            text={text}
            {...rest}
        />
    );
};
