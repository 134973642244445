import React from 'react';
import { Root, Item } from '@radix-ui/react-toggle-group';
import { styled } from '@a2d24-ui/theme';
import { ErrorTooltip } from '@a2d24-ui/error-tooltip';

const StyledToggleGroup = styled(Root, {
    display: 'inline-flex',
    backgroundColor: '$buttonGroupBackground',
    borderRadius: '$2',
    padding: '$0 $3 $0 $0',
    height: '40px',
    width: 'max-content',
    boxSizing: 'border-box',
    variants: {
        active: {
            true: { border: '1px solid $primary' },
        },
        error: {
            true: { border: '1px solid $warning' },
        },
        disabled: {
            true: { opacity: '0.5' },
        },
    },
});

const StyledItem = styled(Item, {
    all: 'unset',
    backgroundColor: '$buttonGroupBackground',
    color: '$primaryText',
    height: '32px',
    boxSizing: 'border-box',
    minWidth: '80px',
    padding: '7px 14px',
    display: 'flex',
    borderRadius: '$2',

    fontSize: '$5',
    fontWeight: '500',
    alignItems: 'center',
    justifyContent: 'center',
    '&:first-child': { marginLeft: 0, borderTopLeftRadius: '$2', borderBottomLeftRadius: '$2' },
    '&:last-child': { borderTopRightRadius: '$2', borderBottomRightRadius: '$2' },
    '&:hover': { backgroundColor: '$primary3' },
    '&:disabled:hover': { backgroundColor: 'transparent' },
    '&[data-state=on]': { backgroundColor: '$backgroundCard', color: '$primary' },
    '&[data-state=on]:disabled:hover': { backgroundColor: '$backgroundCard', color: '$primary' },
    '&:focus': {
        position: 'relative',
        boxShadow: `0 0 0 2px $primaryText`,
        border: '2px solid $primary',
    },
});

export const ButtonGroup = ({
    input,
    meta,
    questionText,
    label,
    disabled,
    type = 'single',
    options = [],
    ariaLabel,
    classNamePrefix,
    ensureValueSelected = false,
    ...rest
}) => {
    const showError = meta?.touched && meta?.invalid;
    return (
        <Container
            {...rest}
            className={classNamePrefix ? `${classNamePrefix}-ToggleContainer` : ''}
        >
            {(questionText || label) && (
                <QuestionText
                    css={{ whiteSpace: 'pre-wrap' }}
                    className={classNamePrefix ? `${classNamePrefix}-ToggleLabel` : ''}
                >
                    {questionText || label}
                </QuestionText>
            )}
            <div>
                <StyledToggleGroup
                    error={showError}
                    active={meta?.active}
                    className={classNamePrefix ? `${classNamePrefix}-ToggleGroup` : ''}
                    aria-label={ariaLabel}
                    {...input}
                    onBlur={(event) => {
                        if (!event.currentTarget.contains(event.relatedTarget)) input?.onBlur();
                    }}
                    type={type}
                    disabled={disabled}
                    value={input?.value || rest.value || ''}
                    onValueChange={(value) => {
                        if (ensureValueSelected && !value) return;
                        input?.onChange && input?.onChange(value);
                        rest?.onChange && rest?.onChange(value);
                    }}
                >
                    {(options || []).map((option, index) => (
                        <StyledItem
                            className={classNamePrefix ? `${classNamePrefix}-ToggleItem` : ''}
                            key={index}
                            disabled={disabled}
                            value={option.value}
                            aria-label={
                                option.ariaLabel ? option.ariaLabel : `Button for ${option.label}`
                            }
                        >
                            {option.label}
                        </StyledItem>
                    ))}
                    {showError && (
                        <ErrorTooltip
                            className={classNamePrefix ? `${classNamePrefix}-ErrorTooltip` : ''}
                            text={meta.error}
                        />
                    )}
                </StyledToggleGroup>
            </div>
        </Container>
    );
};

const Container = styled('div', {
    margin: '20px',
    display: 'grid',
    width: 'max-content',
    gridTemplateColumns: '300px auto',
});

const QuestionText = styled('div', {
    display: 'flex',
    alignItems: 'center',
    color: '$primaryText',
});
