import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const Chip = styled('div', {
    height: '28px',
    margin: '$1',
    fontSize: '$4',
    padding: '4px 12px 4px 10px',
    backgroundColor: '$chipBackground',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    color: '$chipText',
    minWidth: '72px',
    variants: {
        color: {
            gray: {
                color: '$primaryText',
                backgroundColor: '$chipBackgroundGray',
            },
        },
        variant: {
            outline: {
                backgroundColor: '$backgroundCard',
                border: '1px solid $lightText2',
                color: '$primaryText',
            },
            highlight: {
                color: '$secondaryTextDark',
                backgroundColor: '$background',
                border: '1px solid $lightText2',
                fontWeight: 'bold',
            },
        },
    },
});
