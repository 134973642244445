import React, { useEffect, useState } from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ENDPOINT;

const getRemoteItems = async (setItems, type) => {
    const url = `${API_URL}/${type}/view`;

    try {
        const results = await axios.post(url, {});
        if (results) setItems(results?.data);
    } catch (err) {
        console.log(err);
    }
};

const useQueryState = () => {
    const [vehicleList, setVehicleList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    const refreshCallback = React.useCallback(async (list = '', setLoader = null) => {
        if (setLoader) setLoader(true);
        switch (list) {
            case 'vehicle':
                await getRemoteItems(setVehicleList, 'Vehicle');
                break;
            case 'device':
                await getRemoteItems(setDeviceList, 'Device');
                break;
            case 'employee':
                await getRemoteItems(setEmployeeList, 'Employee');
                break;
            default:
                // refresh all if nothing is specified
                await getRemoteItems(setVehicleList, 'Vehicle');
                await getRemoteItems(setDeviceList, 'Device');
                await getRemoteItems(setEmployeeList, 'Employee');
                break;
        }
        if (setLoader) setLoader(false);
    }, []);

    useEffect(() => {
        refreshCallback();
    }, []);

    return {
        vehicles: vehicleList,
        devices: deviceList,
        employees: employeeList,
        refresh: refreshCallback,
    };
};

export default useQueryState;
