export default class EmrState {
    methods() {
        return [
            {
                id: 'emr-state.getAccessToken',
                generator: () => () => {
                    const { authStore } = require('./stores').default;
                    return authStore.emrToken;
                },
            },
        ];
    }
}
