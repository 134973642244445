import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from 'jwt-decode';
import * as Sentry from '@sentry/react';

import {
    associateMFA,
    challengeAuthenticator,
    listMFA,
    login,
    MFA_SCOPES,
    MFA_TYPE_EMAIL,
    MFA_TYPE_SMS,
    removeAuthenticator,
    resetPassword,
    verifyMFA,
} from '@a2d24/auth0-utils';

const MFA_FOR_AUTHENTICATION = 'authentication'; // User is attempting to authenticate
const MFA_FOR_AUTHENTICATORS = 'authenticators'; // User is attempting to manage MFA authenticators
const DEFAULT_REALM = 'Netcare-Users';

export default class MfaStore {
    config = {};
    username = null;
    password = null; // This should be cleared once all flows are completed
    realm = DEFAULT_REALM;
    requestInFlight = false;
    accessToken = null;
    deviceId = null;
    rememberMFA = false;
    status = 'Log in';
    mfaPending = false;
    mfaToken = null;
    mfaAuthToken = null; // An alternative token used when the user authenticates against the mfa audience
    errorMessage = null;
    mfaReason = null;
    mfaAuthenticators = [];
    mfaAuthenticatorsFetched = false;
    challenge = {};
    failedLoginAttempts = 0;

    // These flags allow the UI to track the status of adding a new authenticator
    associationInProgress = false;
    associationSuccessful = false;

    // Should match almost one to one with above. Currently only config, deviceId, username and realm are not reset
    reset(includePassword) {
        // Special case
        if (includePassword) this.password = null;

        // this.realm = DEFAULT_REALM;
        this.accessToken = null;
        this.status = 'Log in';
        this.mfaPending = false;
        this.rememberMFA = false;
        this.mfaToken = null;
        this.mfaAuthToken = null;
        this.requestInFlight = false;
        this.errorMessage = null;
        this.mfaReason = null;
        this.mfaAuthenticators = [];
        this.mfaAuthenticatorsFetched = false;
        this.challenge = {};
        this.associationInProgress = false;
        this.associationSuccessful = false;
        Sentry.configureScope((scope) => scope.setUser(null));
    }

    constructor(
        domain,
        client_id,
        audience,
        mfa_audience,
        localStorageCacheKey,
        { mfa_domain = null, mfa_domain_protocol = 'https' }
    ) {
        makeAutoObservable(this);

        this.config = {
            domain,
            client_id,
            audience,
            mfa_audience,
            localStorageCacheKey,
            mfa_domain: mfa_domain || domain,
            mfa_domain_protocol: mfa_domain_protocol,
        };

        // autorun(() => {
        //     console.debug(toJS(this));
        //     console.log(this.status);
        // });

        this.loadAccessToken();
    }

    // Derived state

    get mfaAuthenticatorsAvailable() {
        return this.mfaAuthenticators > 0;
    }

    get hasNoActiveAuthenticators() {
        return this.mfaAuthenticators.filter((a) => a.active).length === 0;
    }

    get authenticated() {
        return !!this.accessToken;
    }

    get challengePending() {
        return !!this.challenge?.oob_code;
    }

    // Updates to state

    updateStatus(newStatus) {
        this.status = newStatus;
    }

    incrementFailedLoginAttempts() {
        if (this.realm === 'Netcare-Users') this.failedLoginAttempts = this.failedLoginAttempts + 1;
    }

    setAuthenticated(authResponse) {
        this.updateStatus('Authenticated');
        this.setAccessToken(authResponse.access_token);
        this.setPassword(null);
    }

    setMfaRequired(mfaRequest) {
        this.mfaPending = true;
        this.mfaToken = mfaRequest.mfa_token;
        this.updateStatus('Multifactor authentication required');
        this.fetchMfaAuthenticators();
    }

    clearMfaRequired() {
        this.mfaPending = false;
        this.mfaToken = null;
    }

    setRequestInFlight(value) {
        this.requestInFlight = value;
    }

    setMfaAuthenticators(authenticators) {
        this.mfaAuthenticators = authenticators;
        this.updateStatus(
            authenticators.length > 0
                ? 'MFA Authenticators Available'
                : 'No MFA Authenticators available'
        );
        this.setMfaAuthenticatorsFetched(true);
    }

    setErrorMessage(value) {
        this.errorMessage = value;
        if (value) this.updateStatus('Authentication error');
    }

    setMfaAuthenticatorsFetched(value) {
        this.mfaAuthenticatorsFetched = value;
    }

    setUsername(username) {
        this.username = username;
        if (username.endsWith('@a2d24.com')) this.setRealm('Username-Password-Authentication');
        else this.setRealm(DEFAULT_REALM);
    }

    setPassword(password) {
        this.password = password;
    }

    setRealm(realm) {
        this.realm = realm;
        localStorage.setItem(`${this.config.localStorageCacheKey}-LastUsedRealm`, this.realm);
    }

    abandonChallenge() {
        this.setChallenge({});
    }

    setChallenge(challenge) {
        this.challenge = challenge;
    }

    setMfaReason(reason) {
        this.mfaReason = reason;
    }

    setMfaAuthToken(token) {
        this.mfaAuthToken = token;
        this.setPassword(null);
    }

    setAssociationInProgress(newInProgressValue) {
        this.associationInProgress = newInProgressValue;
        this.associationSuccessful = false;
    }

    setAssociationSuccessful(value) {
        this.associationSuccessful = value;
    }

    setRememberMFA(value) {
        this.rememberMFA = value;
    }

    // Actions

    async login(extraParams = {}) {
        this.reset(false);
        this.setRequestInFlight(true);
        this.setErrorMessage(null);
        // Set Sentry Username
        const username = this.username;
        const user = { username };

        if (username.toLowerCase().match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/))
            user['email'] = username;
        Sentry.setUser(user);

        try {
            this.updateStatus('Logging in...');
            const response = await login(this.username, this.password, {
                domain: this.config.domain,
                client_id: this.config.client_id,
                audience: this.config.audience,
                realm: this.realm,
                scope: 'openid',
                extraParams: {
                    device_id: this.deviceId,
                    mfa_token: this.loadMFAToken(),
                    ...extraParams,
                },
            });

            if (response && response.access_token) {
                this.setAuthenticated(response);
            }
            this.setRequestInFlight(false);
            this.failedLoginAttempts = 0;
        } catch (e) {
            const errorResponse = e?.response?.data;
            if (errorResponse?.error === 'mfa_required') {
                this.setMfaRequired(e.response.data);
                this.setMfaReason(MFA_FOR_AUTHENTICATION);
                // Dont set inFlight message as setMfaRequired will use this flag as well
            } else if (errorResponse?.error_description.startsWith('mfa_required')) {
                this.setMfaRequired({ mfa_token: errorResponse?.error_description.split(':')[1] });
                this.setMfaReason(MFA_FOR_AUTHENTICATION);
            } else {
                this.setPassword(null);
                this.incrementFailedLoginAttempts();
                this.setErrorMessage(errorResponse?.error_description || 'Unable to authenticate');
                this.setRequestInFlight(false);
            }
        }
    }

    async getMFAToken(extraParams = {}) {
        this.setRequestInFlight(true);
        try {
            this.updateStatus('Fetching MFA Token...');
            this.setMfaReason(MFA_FOR_AUTHENTICATORS);
            const response = await login(this.username, this.password, {
                domain: this.config.domain,
                client_id: this.config.client_id,
                audience: this.config.mfa_audience,
                realm: this.realm,
                scope: MFA_SCOPES,
                device_id: this.deviceId,
                extraParams: {
                    device_id: this.deviceId,
                    mfa_token: this.loadMFAToken(),
                    ...extraParams,
                },
            });

            if (response && response.access_token) {
                this.updateStatus('MFA Token Available');
                this.setMfaAuthToken(response.access_token);
            }
            this.setRequestInFlight(false);
        } catch (e) {
            const errorResponse = e?.response?.data;
            if (errorResponse?.error === 'mfa_required') {
                this.updateStatus('MFA Required to access MFA Token');
                this.setMfaRequired(e.response.data);
                // Dont set inFlight message as setMfaRequired will use this flag as well
            } else if (errorResponse?.error_description.startsWith('mfa_required')) {
                this.updateStatus('MFA Required to access MFA Token');
                this.setMfaRequired({ mfa_token: errorResponse?.error_description.split(':')[1] });
            } else {
                this.setErrorMessage(
                    errorResponse?.error_description || 'Unable to authenticate MFA token'
                );
                this.setRequestInFlight(false);
            }
        }
        this.setRequestInFlight(false);
    }

    async fetchMfaAuthenticators() {
        this.setRequestInFlight(true);
        this.setMfaAuthenticatorsFetched(false); // setMfaAuthenticators will set this flag if successful

        const token = this.mfaToken || this.mfaAuthToken;

        this.updateStatus('Fetching MFA Authenticators');
        try {
            const mfaAuthenticators = await listMFA(token, {
                domain: this.config.mfa_domain,
                protocol: this.config.mfa_domain_protocol,
            });
            this.setMfaAuthenticators(mfaAuthenticators);
        } catch (e) {
            console.log(e);
            this.updateStatus('Unable to fetch MFA Authenticators');
        }

        this.setRequestInFlight(false);
    }

    associateSMS(mobile_number) {
        this.associateAuthenticator(mobile_number, MFA_TYPE_SMS);
    }

    associateEmail(email_address) {
        this.associateAuthenticator(email_address, MFA_TYPE_EMAIL);
    }

    async associateAuthenticator(mfa_destination, type) {
        // Check if the user has no MFA defined yet
        const token = this.mfaToken || this.mfaAuthToken;
        this.setErrorMessage(null);
        if (token) {
            this.setRequestInFlight(true);

            this.updateStatus('Associating Authenticator');

            try {
                const associateResponse = await associateMFA(token, mfa_destination, {
                    type: type,
                    domain: this.config.mfa_domain,
                    protocol: this.config.mfa_domain_protocol,
                });
                this.updateStatus('Awaiting OTP');
                this.setChallenge(associateResponse);
            } catch (e) {
                this.setErrorMessage(
                    e?.response?.data?.error_description ||
                        'An error occurred while trying to associate a new MFA Authenticator'
                );
                this.updateStatus('Unable to associate authenticator');
            }

            this.setRequestInFlight(false);
        }
    }

    async verifyMFA(binding_code) {
        const token = this.mfaToken || this.mfaAuthToken;
        this.setErrorMessage(null);

        if (this.challenge?.oob_code) {
            this.setRequestInFlight(true);
            this.updateStatus('Verifying OTP');

            let verifyResponse = null;
            try {
                verifyResponse = await verifyMFA(token, this.challenge.oob_code, binding_code, {
                    domain: this.config.mfa_domain,
                    protocol: this.config.mfa_domain_protocol,
                    client_id: this.config.client_id,
                });
            } catch (e) {
                this.updateStatus('OTP Verification failed');
                this.setErrorMessage(
                    e?.response?.data?.error_description === 'Invalid binding_code.'
                        ? 'The code entered is not valid. Please try again.'
                        : e?.response?.data?.error_description
                );
            }

            if (verifyResponse?.mfa_verification && this.mfaReason === MFA_FOR_AUTHENTICATION) {
                try {
                    const loginResponse = await login(this.username, this.password, {
                        domain: this.config.domain,
                        client_id: this.config.client_id,
                        audience: this.config.audience,
                        realm: this.realm,
                        scope: 'openid',
                        extraParams: {
                            device_id: this.deviceId,
                            mfa_verification: verifyResponse?.mfa_verification,
                        },
                    });
                    this.setAuthenticated(loginResponse);

                    this.clearMfaRequired();
                    this.setChallenge({});
                    this.updateStatus('OTP Verification Successful');

                    if (this.rememberMFA) {
                        this.saveMFAToken(loginResponse?.access_token);
                    }
                    if (this.associationInProgress) this.setAssociationSuccessful(true);
                } catch (e) {
                    this.updateStatus('OTP Login Failed');
                    this.setErrorMessage(
                        e?.response?.data?.error_description ||
                            'An error occurred while logging in. Please try again'
                    );
                }
            } else if (
                verifyResponse?.mfa_verification &&
                this.mfaReason === MFA_FOR_AUTHENTICATORS
            ) {
                if (!this.associationInProgress) {
                    const loginForMFAResponse = await login(this.username, this.password, {
                        domain: this.config.domain,
                        client_id: this.config.client_id,
                        audience: this.config.mfa_audience,
                        realm: this.realm,
                        scope: MFA_SCOPES,
                        device_id: this.deviceId,
                        extraParams: {
                            device_id: this.deviceId,
                            mfa_verification: verifyResponse?.mfa_verification,
                        },
                    });
                    this.setMfaAuthToken(loginForMFAResponse?.access_token);
                    if (this.rememberMFA) {
                        this.saveMFAToken(loginForMFAResponse?.access_token);
                    }
                }

                this.clearMfaRequired();
                this.setChallenge({});
                this.updateStatus('OTP Verification Successful');

                if (this.associationInProgress) this.setAssociationSuccessful(true);
            }

            this.setRequestInFlight(false);
        } else {
            this.reset(); // How did we get here? Bail
        }
    }

    async challengeAuthenticator(authenticator_id) {
        this.setRequestInFlight(true);
        this.updateStatus('Requesting OTP');

        try {
            const challengeResponse = await challengeAuthenticator(
                this.mfaToken,
                authenticator_id,
                {
                    domain: this.config.mfa_domain,
                    protocol: this.config.mfa_domain_protocol,
                    client_id: this.config.client_id,
                }
            );

            this.setChallenge(challengeResponse);
            this.updateStatus('Waiting for user input of OTP');
        } catch (e) {
            this.updateStatus('Unable to challenge authenticator');
            this.setErrorMessage(
                e?.response?.data?.error_description || 'Unable to challenge authenticator'
            );
        }
        this.setRequestInFlight(false);
    }

    async removeAuthenticator(authenticator_id) {
        this.setRequestInFlight(true);
        const token = this.mfaToken || this.mfaAuthToken;

        try {
            const response = await removeAuthenticator(token, authenticator_id, {
                domain: this.config.mfa_domain,
                protocol: this.config.mfa_domain_protocol,
            });
        } catch (e) {
            console.log('Unable to remove authenticator');
        }

        this.setRequestInFlight(false);
        this.fetchMfaAuthenticators();
    }

    async resetPassword() {
        try {
            const result = await this.resetPasswordPromise();
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async resetPasswordPromise() {
        // Just a quick workaround to get a raw promise for login flow - this should ideally be driven from state only
        return resetPassword(this.username, {
            domain: this.config.domain,
            client_id: this.config.client_id,
            connection: this.realm,
        });
    }

    setAccessToken(token) {
        this.accessToken = token;
        if (this.config.localStorageCacheKey) {
            localStorage.setItem(this.config.localStorageCacheKey, token);
            localStorage.setItem(`${this.config.localStorageCacheKey}-Username`, this.username);
            localStorage.setItem(`${this.config.localStorageCacheKey}-Realm`, this.realm);
        }
    }

    clearAccessToken() {
        this.accessToken = null;
        if (this.config.localStorageCacheKey) {
            localStorage.removeItem(this.config.localStorageCacheKey);
            localStorage.removeItem(`${this.config.localStorageCacheKey}-Username`);
            localStorage.removeItem(`${this.config.localStorageCacheKey}-Realm`);
        }
    }

    getAccessToken(decode = false) {
        if (!this.authenticated) return null;
        return decode ? jwt_decode(this.accessToken) : this.accessToken;
    }

    loadAccessToken() {
        if (this.config.localStorageCacheKey) {
            this.accessToken = localStorage.getItem(this.config.localStorageCacheKey);
            this.username = localStorage.getItem(`${this.config.localStorageCacheKey}-Username`);

            const realm = localStorage.getItem(`${this.config.localStorageCacheKey}-Realm`);
            if (realm) {
                this.realm = realm;
            } else {
                this.realm =
                    localStorage.getItem(`${this.config.localStorageCacheKey}-LastUsedRealm`) ||
                    DEFAULT_REALM;
            }

            this.deviceId = this.loadOrCreateDeviceId();
        }
    }

    loadOrCreateDeviceId() {
        const key = `${this.config.localStorageCacheKey}-DeviceID`;
        let deviceId = localStorage.getItem(key);
        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem(key, deviceId);
        }

        return deviceId;
    }

    saveMFAToken(token) {
        if (!token) return;
        const decoded = jwt_decode(token);
        const claimKey = `${this.config.audience}/mfa_token`;
        const mfaToken = decoded[claimKey];
        if (this.config.localStorageCacheKey && mfaToken) {
            const storageKey = `${this.config.localStorageCacheKey}-MfaToken-${
                this.realm
            }:${this.username.toUpperCase()}`;

            localStorage.setItem(storageKey, mfaToken);
        }
    }

    loadMFAToken(decode = false) {
        if (this.config.localStorageCacheKey) {
            const storageKey = `${this.config.localStorageCacheKey}-MfaToken-${
                this.realm
            }:${this.username.toUpperCase()}`;

            const token = localStorage.getItem(storageKey);
            if (token) return decode ? jwt_decode(token) : token;
        }
        return null;
    }

    clearMFAToken() {
        if (this.config.localStorageCacheKey) {
            const storageKey = `${this.config.localStorageCacheKey}-MfaToken-${
                this.realm
            }:${this.username.toUpperCase()}`;
            localStorage.removeItem(storageKey);
        }
    }

    logout() {
        this.clearAccessToken();
        this.reset();
        this.username = null;
        Sentry.configureScope((scope) => scope.setUser(null));
    }
}
