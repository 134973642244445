import { styled } from '@a2d24-ui/theme/stitches';
import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons';

export const HeaderContainer = styled('div', {
    padding: '0px 20px',
    display: 'grid',
    flexDirection: 'row',
    columnGap: '20px',
    alignItems: 'center',
    margin: '5px 20px 10px',
    width: 'calc(100% - 40px)',
    variants: {
        type: {
            vehicle: {
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 220px',
            },
            employee: {
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 220px',
            },
            device: {
                rowGap: '20px',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 220px',
            },
        },
    },
});

export const EntryContainer = styled('div', {
    padding: '4px 20px',
    display: 'grid',
    flexDirection: 'row',
    columnGap: '20px',
    alignItems: 'center',
    background: 'white',
    margin: '5px 0',
    borderRadius: '5px',
    boxShadow: 'rgb(0 0 0 / 16%) 1px 2px 4px',
    variants: {
        type: {
            vehicle: {
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 220px',
            },
            employee: {
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 220px',
            },
            device: {
                rowGap: '10px',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 220px',
            },
        },
    },
});

export const StyledChevron = styled(ChevronDownIcon, {
    color: '$primaryBlack',
    transition: 'transform 200ms cubic-bezier(0.87, 0, 0.13, 1)',
    '&[data-state="open"]': { transform: 'rotate(-180deg)' },
    width: 20,
    height: 20,
});

export const StyledChevronHorizontal = styled(ChevronRightIcon, {
    color: '$primaryBlack',
    transition: 'transform 200ms cubic-bezier(0.87, 0, 0.13, 1)',
    '&[direction="left"]': { transform: 'rotate(-180deg)' },
    width: 20,
    height: 20,
});
