import Queue from './Queue';
import { v4 as uuidv4 } from 'uuid';
import Socket from '@a2d24/socket';
import { generateUrlWithParameters } from '@a2d24/socket/socketUtils';
const FIVE_MINUTES = 1000 * 60 * 5;

const sessionId = uuidv4();

class DeadPoll {
    constructor(websocketEndpoint, apiEndpoint, token = null) {
        this.token = token;
        this.apiEndpoint = apiEndpoint;
        this.websocketEndpoint = websocketEndpoint;

        this.connected = false;
        this.subscriptions = {};
        this.data = {};

        this._locks = {};
        this._queueLock = false;
        this._queue = new Queue();
        this._keepAliveTimer = null;

        this._socket = new Socket(
            this.onChange.bind(this),
            this.onMessage.bind(this),
            this.onDisconnect.bind(this),
            this.onError.bind(this)
        );
    }

    reSubscribe() {
        Object.keys(this.data).forEach((identifier) => {
            this.sendSubscriptionAction('subscribe', identifier);
        });
    }

    onChange(state) {
        const self = this;
        this.connected = state;
        if (state === true) {
            this.reSubscribe();
            this._keepAliveTimer = setInterval(() => {
                self._socket.sendMessage({ action: 'ping' });
            }, FIVE_MINUTES);
        } else {
            clearInterval(this._keepAliveTimer);
            if (Object.keys(this.subscriptions).length > 0 && !this._socket.shouldConnect)
                this._connectToSocket();
        }
    }

    _isStaleUpdate(data) {
        const newSequenceNumber = parseInt(data['sequence_number']);
        return parseInt(this.data[data.identifier]['sequence_number']) >= newSequenceNumber;
    }

    _isValidUpdate(data) {
        const newSequenceNumber = parseInt(data['sequence_number']);
        return parseInt(this.data[data.identifier]['sequence_number']) + 1 === newSequenceNumber;
    }

    sendDataToSubscribers(identifier, updatePayload) {
        Object.keys(this.subscriptions[identifier]).forEach((caller) => {
            const callback = this.subscriptions[identifier][caller];

            const data = this.data[identifier]['documents'];

            if (updatePayload) callback(data, updatePayload);
            else this.sendInitialData(callback, identifier);
        });
    }

    setData(data) {
        this.data[data.identifier] = {
            ...data,
            documents: Object.assign({}, ...data.documents.map((x) => ({ [x.id]: x.data }))),
            changed_data: {},
            sequence_number: parseInt(data['sequence_number']),
        };
        this.sendDataToSubscribers(data.identifier);
    }

    async getData(identifier) {
        if (this.data[identifier])
            return new Promise(function (resolve) {
                resolve(this.data[identifier]['documents']);
            });
        else return this.refreshData(identifier);
    }

    async refreshData(identifier) {
        if (this._locks[identifier]) return;
        this._locks[identifier] = true;

        const headers = { 'Content-Type': 'application/json' };
        if (this.token) headers['Authorization'] = this.token;

        return fetch(this.apiEndpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ identifier }),
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.status);
                }
                return response.json();
            })
            .then((data) => {
                this.setData(data);
                return data.documents;
            })
            .catch((e) => {
                console.error('An error occurred: ', e);
            })
            .finally(() => {
                delete this._locks[identifier];
            });
    }

    processQueue() {
        if (this._queueLock) return;
        this._queueLock = true;
        while (this._queue.length > 0) {
            const data = this._queue.dequeue();

            if (!data.identifier) continue;

            if (!this.data.hasOwnProperty(data.identifier)) {
                //To cater for an attempt to unsubscribe but the websocket was down.
                //This is an edge case until we delete the subscription on disconnect on AWS
                this.sendSubscriptionAction('unsubscribe', data.identifier);
                continue;
            }

            if (this._isStaleUpdate(data)) continue;
            if (!this._isValidUpdate(data)) {
                this.refreshData(data.identifier);
                continue;
            }
            const recordId = data.changed_data.id;

            let documents = this.data[data.identifier]['documents'];

            if (data.method.toLowerCase() === 'remove') {
                const { [recordId]: value, ...rest } = documents;
                documents = rest;
            } else documents[recordId] = data.changed_data.data;

            this.data[data.identifier] = {
                documents,
                ...data,
            };
            this.sendDataToSubscribers(data.identifier, data);
        }
        this._queueLock = false;
    }

    onMessage(data) {
        this._queue.enqueue(data);
        this.processQueue(data);
    }

    onDisconnect() {
        console.debug('The deadpoll socket has disconnected');
    }

    onError() {
        console.error('The deadpoll socket experienced an error');
    }

    sendInitialData(callback, identifier) {
        callback(this.data[identifier]['documents'], {
            ...this.data[identifier],
            method: 'init',
            sequence_number: this.data[identifier]['sequence_number'],
            data: this.data[identifier]['data'],
        });
    }

    _connectToSocket() {
        this._socket.connect(
            generateUrlWithParameters(this.websocketEndpoint, { token: this.token, sessionId })
        );
    }

    async subscribe({ identifier, callback }) {
        const componentId = uuidv4();

        if (!this.connected) this._connectToSocket();

        if (!this.subscriptions[identifier]) this.subscriptions[identifier] = {};

        this.subscriptions[identifier][componentId] = callback;

        if (this.data[identifier]) this.sendInitialData(callback, identifier);
        else await this.refreshData(identifier);
        if (this.connected) this.sendSubscriptionAction('subscribe', identifier);
        return componentId;
    }

    sendSubscriptionAction(action, identifier) {
        const message = {
            action,
            identifier,
            session_id: sessionId,
        };
        if (this.token) message['token'] = this.token;

        this._socket.sendMessage(message);
    }

    unsubscribeAll = () => {
        Object.keys(this.subscriptions).forEach((identifier) =>
            this.sendSubscriptionAction('unsubscribe', identifier)
        );
        this.subscriptions = {};
        this.data = {};
        this._socket.disconnect();
    };

    async unsubscribe({ identifier, componentId }) {
        if (!this.subscriptions[identifier]) return;
        if (!this.subscriptions[identifier][componentId]) return;
        delete this.subscriptions[identifier][componentId];

        if (Object.keys(this.subscriptions[identifier]).length === 0) {
            delete this.subscriptions[identifier];
            if (!this.data[identifier]) return;
            delete this.data[identifier];
        }

        this.sendSubscriptionAction('unsubscribe', identifier);

        if (Object.keys(this.data).length === 0) {
            this._socket.disconnect();
        }
    }
}

export default DeadPoll;
