import React, { useState } from 'react';
import { DialogDescription, DialogTitle } from '@a2d24-ui/dialog';
import { styled, Text } from '@a2d24-ui/theme';
import { TextInput } from '@a2d24-ui/input';
import { Select } from '@a2d24-ui/select/src';
import { Checkbox } from '@a2d24-ui/checkbox';
import { Button } from '@a2d24-ui/button';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

const managementGroups = [
    { label: 'Coastal Ambo Info', value: 'Coastal_Ambo_Info' },
    { label: 'Coastal RV Info', value: 'Coastal_RV_Info' },
    { label: 'Gauteng Ambo Info', value: 'Gauteng_Ambo_Info' },
    { label: 'Gauteng RV Info', value: 'Gauteng_RV_Info' },
    { label: 'Inland Ambo Info', value: 'Inland_Ambo_Info' },
    { label: 'Inland RV Info', value: 'Inland_RV_Info' },
];

const provinces = [
    { label: 'Eastern Cape', value: 'Eastern Cape' },
    { label: 'Free State', value: 'Free State' },
    { label: 'Gauteng', value: 'Gauteng' },
    { label: 'KwaZulu-Natal', value: 'KwaZulu-Natal' },
    { label: 'Limpopo', value: 'Limpopo' },
    { label: 'Mpumalanga', value: 'Mpumalanga' },
    { label: 'Northern Cape', value: 'Northern Cape' },
    { label: 'North West', value: 'North West' },
    { label: 'Western Cape', value: 'Western Cape' },
];

const registerItem = async (setLoading, resetForm, controller, method, payload) => {
    if (setLoading) setLoading(true);
    const url = `${API_URL}/${controller}/${method}`;

    axios
        .post(url, payload)
        .then((r) => {
            engine.broadcastMessage('snackbar', {
                type: 'success',
                message: 'Successfully saved',
            });
            if (resetForm) resetForm();
        })
        .catch((err) => {
            engine.broadcastMessage('snackbar', {
                type: 'error',
                message: err?.response?.data || 'An error has occurred',
            });
        })
        .finally(() => {
            if (setLoading) setLoading(false);
        });
};

// Render Handlers

const RenderEmployeeRegisterForm = (props) => {
    const [employeeNumber, setEmployeeNumber] = useState(props?.employee_number || '');
    const [name, setName] = useState(props?.name || '');
    const [surname, setSurname] = useState(props?.surname || '');
    const [hpcsa, setHpcsa] = useState(props?.hpcsa_number || '');
    const [loading, setLoading] = useState(false);

    const [confirmed, setConfirmed] = useState(false);

    const resetForm = () => {
        setName(props?.name || '');
        setSurname(props?.surname || '');
        setHpcsa(props?.hpcsa_number || '');
        setEmployeeNumber(props?.employee_number || '');
    };

    return (
        <>
            <DialogTitle>{props.employee_number ? 'Edit' : 'Add'} Employee</DialogTitle>
            <DialogDescription>
                Please enter the details of the <b>Employee</b> you would like to{' '}
                {props.employeeNumber ? 'edit' : 'register'}:
            </DialogDescription>
            <div>
                <Text.Primary>{'Employee number (e.g 0001234567)'}</Text.Primary>
                <TextInput
                    onInput={(value) => setEmployeeNumber(value?.target?.value)}
                    value={employeeNumber}
                    placeholder={'Employee number'}
                    disabled={props?.employee_number}
                ></TextInput>
            </div>
            <div>
                <Text.Primary>{'Name (e.g. John)'}</Text.Primary>
                <TextInput
                    onInput={(value) => setName(value?.target?.value)}
                    value={name}
                    placeholder={'Name'}
                ></TextInput>
            </div>
            <div>
                <Text.Primary>{'Surname (e.g. Doe)'}</Text.Primary>
                <TextInput
                    onInput={(value) => setSurname(value?.target?.value)}
                    value={surname}
                    placeholder={'Surname'}
                ></TextInput>
            </div>
            <div>
                <Text.Primary>
                    {'HPCSA number (e.g. Doe) '}
                    <i>optional</i>
                </Text.Primary>
                <TextInput
                    onInput={(value) => setHpcsa(value?.target?.value)}
                    value={hpcsa}
                    placeholder={'HPCSA number'}
                ></TextInput>
            </div>
            <FormConfirmContainer>
                <div>
                    <Checkbox
                        onCheckedChange={(value) => setConfirmed(value)}
                        checked={confirmed}
                        text={
                            <Text.Primary>
                                {`Please confirm you would like to perform the following action - `}
                                {props.employee_number ? 'Update' : 'Add'} '
                                <b>
                                    {name} {surname}
                                </b>
                                ' with employee number '<b>{employeeNumber}</b>'{' '}
                                {hpcsa ? `and HPCSA number '${hpcsa}'` : ''}
                            </Text.Primary>
                        }
                    ></Checkbox>
                </div>
                <div style={{ justifyContent: 'right', display: 'flex' }}>
                    <Button
                        disabled={!confirmed}
                        color="secondary"
                        onClick={() => {
                            registerItem(
                                setLoading,
                                resetForm,
                                'Employee',
                                props.employee_number ? 'edit' : 'add',
                                {
                                    employee_number: props.employee_number || employeeNumber,
                                    name,
                                    surname,
                                    hpcsa_number: hpcsa,
                                }
                            );
                        }}
                    >
                        {props.employee_number
                            ? loading
                                ? 'Updating...'
                                : 'Update'
                            : loading
                            ? 'Registering...'
                            : 'Register'}
                    </Button>
                </div>
            </FormConfirmContainer>
        </>
    );
};

const RenderDeviceRegisterForm = (props) => {
    const callSigns = props?.vehicles?.map((vehicle) => {
        return { label: vehicle.call_sign, value: vehicle.call_sign };
    });
    const [mobileNumber, setMobileNumber] = useState('+27');
    const [callSign, setCallSign] = useState(null);
    const [province, setProvince] = useState(null);
    const [base, setBase] = useState('');
    const [loading, setLoading] = useState(false);

    const [confirmed, setConfirmed] = useState(false);

    const resetForm = () => {
        setMobileNumber('+27');
        setCallSign(null);
        setProvince(null);
        setBase('');
    };

    const parseMobile = (number) => {
        if (number.length < 4) return '+27';
        return number.slice(0, 12)?.replace(/[^0-9\+]/g, '');
    };

    return (
        <>
            <DialogTitle>Add Device</DialogTitle>
            <DialogDescription>
                Please enter the details of the <b>Device</b> you would like to register (and link
                to a callsign):
            </DialogDescription>
            <div>
                <Text.Primary>{'Mobile number (e.g +27831234567)'}</Text.Primary>
                <TextInput
                    onInput={(value) => setMobileNumber(parseMobile(value?.target?.value))}
                    value={mobileNumber}
                    placeholder={'Mobile number'}
                ></TextInput>
            </div>
            <div>
                <Text.Primary>{'Callsign'}</Text.Primary>
                <Select
                    options={callSigns}
                    onChange={(value) => setCallSign(value)}
                    value={callSign}
                    css={{ width: '100%' }}
                />
            </div>
            <div>
                <Text.Primary>{'Province'}</Text.Primary>
                <Select
                    options={provinces}
                    onChange={(value) => setProvince(value)}
                    value={province}
                    css={{ width: '100%' }}
                />
            </div>
            <div>
                <Text.Primary>{'Base (e.g. Netcare Unitas Hospital)'}</Text.Primary>
                <TextInput
                    onInput={(value) => setBase(value?.target?.value)}
                    value={base}
                    placeholder={'Base'}
                ></TextInput>
            </div>
            <FormConfirmContainer>
                <div>
                    <Checkbox
                        onCheckedChange={(value) => setConfirmed(value)}
                        checked={confirmed}
                        text={
                            <Text.Primary>
                                {`Please confirm you would like to perform the following action - `}
                                Add device '<b>{mobileNumber}</b>' to vehicle '
                                <b>{callSign?.value}</b>' with base '<b>{base}</b>' situated in '
                                <b>{province?.value}</b>'
                            </Text.Primary>
                        }
                    ></Checkbox>
                </div>
                <div style={{ justifyContent: 'right', display: 'flex' }}>
                    <Button
                        disabled={!confirmed}
                        color="secondary"
                        onClick={() => {
                            registerItem(setLoading, resetForm, 'Device', 'add', {
                                mobile_number: mobileNumber,
                                call_sign: callSign?.value,
                                province: province?.value,
                                base: base,
                            });
                        }}
                    >
                        {loading ? 'Registering...' : 'Register'}
                    </Button>
                </div>
            </FormConfirmContainer>
        </>
    );
};

const RenderDeviceReassignForm = (props) => {
    const callSigns = props?.vehicles?.map((vehicle) => {
        return { label: vehicle.call_sign, value: vehicle.call_sign };
    });
    const [mobileNumber, setMobileNumber] = useState(props.cellphone_number);
    const [callSign, setCallSign] = useState({ label: props.call_sign, value: props.call_sign });
    const [province, setProvince] = useState({ label: props.province, value: props.province });
    const [base, setBase] = useState(props.base);
    const [loading, setLoading] = useState(false);

    const [confirmed, setConfirmed] = useState(false);

    const parseMobile = (number) => {
        if (number.length < 4) return '+27';
        return number.slice(0, 12)?.replace(/[^0-9\+]/g, '');
    };

    const resetForm = () => {
        setCallSign({ label: props.call_sign, value: props.call_sign });
        setProvince({ label: props.province, value: props.province });
        setBase({ label: props.base, value: props.base });
    };

    return (
        <>
            <DialogTitle>Reassign Device</DialogTitle>
            <DialogDescription>
                Please enter the updated details of the <b>Device</b> you would like to assign a new
                callsign to:
            </DialogDescription>
            <div>
                <Text.Primary>{'Mobile number (e.g +27831234567)'}</Text.Primary>
                <TextInput
                    disabled={true}
                    onInput={(value) => setMobileNumber(parseMobile(value?.target?.value))}
                    value={mobileNumber}
                    placeholder={'Mobile number'}
                ></TextInput>
            </div>
            <div>
                <Text.Primary>{'Callsign'}</Text.Primary>
                <Select
                    options={callSigns}
                    onChange={(value) => setCallSign(value)}
                    value={callSign}
                    css={{ width: '100%' }}
                />
            </div>
            <div>
                <Text.Primary>{'Province'}</Text.Primary>
                <Select
                    // disabled={true}
                    options={provinces}
                    onChange={(value) => setProvince(value)}
                    value={province}
                    css={{ width: '100%' }}
                />
            </div>
            <div>
                <Text.Primary>{'Base (e.g. Netcare Unitas Hospital)'}</Text.Primary>
                <TextInput
                    // disabled={true}
                    onInput={(value) => setBase(value?.target?.value)}
                    value={base}
                    placeholder={'Base'}
                ></TextInput>
            </div>
            <FormConfirmContainer>
                <div>
                    <Checkbox
                        onCheckedChange={(value) => setConfirmed(value)}
                        checked={confirmed}
                        text={
                            <Text.Primary>
                                {`Please confirm you would like to perform the following action - `}
                                Reassign device '<b>{mobileNumber}</b>' with callsign '
                                <b>{props.call_sign}</b>' to vehicle '<b>{callSign?.value}</b>' with
                                base '<b>{base}</b>' situated in '<b>{province.value}</b>'
                            </Text.Primary>
                        }
                    ></Checkbox>
                </div>
                <div style={{ justifyContent: 'right', display: 'flex' }}>
                    <Button
                        disabled={!confirmed}
                        color="secondary"
                        onClick={() => {
                            registerItem(setLoading, resetForm, 'Device', 'reset', {
                                mobile_number: mobileNumber,
                                call_sign: callSign?.value,
                            });
                        }}
                    >
                        {loading ? 'Reassigning...' : 'Reassign'}
                    </Button>
                </div>
            </FormConfirmContainer>
        </>
    );
};

const RenderVehicleRegisterForm = (props) => {
    const [callSign, setCallSign] = useState(props?.call_sign || '');
    const [groups, setGroups] = useState(
        props?.management_groups?.map((group) => {
            return { label: group.replace('_', ' '), value: group };
        }) || null
    );
    const [loading, setLoading] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const resetForm = () => {
        setCallSign('');
        setGroups(null);
    };

    return (
        <>
            <DialogTitle>{props?.call_sign ? 'Edit' : 'Add'} Vehicle</DialogTitle>
            <DialogDescription>
                Please enter the details of the <b>Vehicle</b> you would like to{' '}
                {props?.call_sign ? 'edit' : 'register'}:
            </DialogDescription>
            <div>
                <Text.Primary>{'Callsign (e.g RV99)'}</Text.Primary>
                <TextInput
                    disabled={props?.call_sign}
                    onInput={(value) =>
                        setCallSign(value?.target?.value?.replace(/[^a-zA-Z0-9_]/g, ''))
                    }
                    value={callSign}
                    placeholder={'Callsign'}
                ></TextInput>
            </div>
            <div>
                <Text.Primary>{'Management groups'}</Text.Primary>
                <Select
                    options={managementGroups}
                    onChange={(value) => {
                        setGroups(value);
                    }}
                    value={groups}
                    isMulti
                    css={{ width: '100%' }}
                />
            </div>
            <FormConfirmContainer>
                <div>
                    <Checkbox
                        onCheckedChange={(value) => setConfirmed(value)}
                        checked={confirmed}
                        text={
                            <Text.Primary>
                                {`Please confirm you would like to perform the following action - `}
                                Add vehicle '<b>{callSign}</b>' with management groups '
                                <b>{groups?.map((group) => group.value)?.join(', ')}</b>'
                            </Text.Primary>
                        }
                    ></Checkbox>
                </div>
                <div style={{ justifyContent: 'right', display: 'flex' }}>
                    <Button
                        disabled={!confirmed}
                        color="secondary"
                        onClick={() => {
                            registerItem(
                                setLoading,
                                resetForm,
                                'Vehicle',
                                props?.call_sign ? 'edit' : 'add',
                                {
                                    call_sign: callSign,
                                    management_groups: groups?.map((group) => group.value),
                                }
                            );
                        }}
                    >
                        {props?.call_sign
                            ? loading
                                ? 'Updating...'
                                : 'Update'
                            : loading
                            ? 'Registering...'
                            : 'Register'}
                    </Button>
                </div>
            </FormConfirmContainer>
        </>
    );
};

const useForms = () => {
    return {
        register: {
            device: RenderDeviceRegisterForm,
            employee: RenderEmployeeRegisterForm,
            vehicle: RenderVehicleRegisterForm,
        },
        reassign: {
            device: RenderDeviceReassignForm,
        },
    };
};

export default useForms;

const FormConfirmContainer = styled('div', {
    marginTop: 'auto',
    display: 'flex',
    paddingTop: '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '0.5px solid rgba(0,0,0,0.1)',
});
