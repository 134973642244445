import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const AddIcon = styled(
    React.forwardRef(({ color = 'currentColor', ...props }, forwardedRef) => (
        <svg
            width="12px"
            height="12px"
            viewBox="0 0 12 12"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>0A54668D-0186-4517-8D29-FE98DA1F5BAE</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="04_Medical-History_Full"
                    transform="translate(-866.000000, -590.000000)"
                    fill={color}
                    fillRule="nonzero"
                    stroke={color}
                    strokeWidth="0.4"
                >
                    <g id="Group-2" transform="translate(509.000000, 574.000000)">
                        <g id="Group" transform="translate(358.000000, 17.000000)">
                            <path
                                d="M5,0 C4.70145819,0 4.45945825,0.242013448 4.45945825,0.540540394 L4.45945825,4.45947177 L0.540540394,4.45947177 C0.242013448,4.45947177 0,4.70147441 0,5.00001486 C0,5.29855532 0.242013448,5.54055526 0.540540394,5.54055526 L4.45945825,5.54055526 L4.45945825,9.45945961 C4.45945825,9.75798655 4.70145819,10 5,10 C5.29853911,10 5.54053904,9.75798655 5.54053904,9.45945961 L5.54053904,5.54055526 L9.4594569,5.54055526 C9.75798385,5.54055526 10,5.29855532 10,5.00001486 C10,4.70147441 9.75798385,4.45947177 9.4594569,4.45947177 L5.54053904,4.45947177 L5.54053904,0.540540394 C5.54053904,0.242013448 5.29853911,0 5,0 L5,0 Z"
                                id="Path"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )),
    {}
);
