import React, { useState } from 'react';
import { keyframes, styled } from '@a2d24-ui/theme';
import { mauve } from '@radix-ui/colors';
import { Cross2Icon } from '@radix-ui/react-icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

function Root({ children, ...props }) {
    return <DialogPrimitive.Root {...props}>{children}</DialogPrimitive.Root>;
}

export const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 25,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$lightText',
    cursor: 'pointer',

    '&:hover': { backgroundColor: '$primary4' },
    '&:focus': { boxShadow: `0 0 0 2px $colors$primary3` },
});

const StyledContent = styled(DialogPrimitive.Content, {
    display: 'grid',
    gridTemplateAreas: '"sidebar main"',
    gridTemplateColumns: 'auto 1fr',
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow:
        'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',

    '@bp3': {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxWidth: '50vw',
        height: 'auto',
        maxHeight: '50vh',
    },

    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
        willChange: 'transform',
    },
    '&:focus': { outline: 'none' },
});

const StyledTitle = styled(DialogPrimitive.Title, {
    margin: 0,
    fontWeight: 500,
    color: mauve.mauve12,
    fontSize: 17,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
});

const StyledDescription = styled(DialogPrimitive.Description, {
    margin: '10px 0 20px',
    color: mauve.mauve11,
    fontSize: 15,
    lineHeight: 1.5,
    maxWidth: '25vw',
});

// Aliases
export const Dialog = Root;
export const DialogContent = StyledContent;
export const DialogTitle = StyledTitle;
export const DialogDescription = StyledDescription;
export const DialogClose = DialogPrimitive.Close;

export const StyledCross2Icon = styled(Cross2Icon, {
    width: 25,
    height: 25,
});
